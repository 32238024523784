// import libraries
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import {
  FaHouse,
  FaSailboat,
  FaUser,
  FaGear,
  FaMoneyBillTransfer,
} from 'react-icons/fa6';
import { FaSignOutAlt } from 'react-icons/fa';
import { IoMdAddCircle } from 'react-icons/io';
// import css
import '../assets/styles/sidebar.scss';

/*
Left sidebar component
Navigation menu with links to other pages.
Display only when user is logged-in.
*/

function Sidebar() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [menu, setMenu] = useState('dash');
  const [stateDisplay, setStateDisplay] = useState('');

  useEffect(() => {
    const boat = document.getElementById('boat');
    const people = document.getElementById('people');

    if (boat.classList.contains('active')) {
      setStateDisplay('boat');
    } else if (people.classList.contains('active')) {
      setStateDisplay('people');
    } else {
      setStateDisplay('');
    }
  }, [menu]);

  const logout = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      localStorage.removeItem('ids');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/login');
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <header className="sidebar">
        <ul className="sidebar__wrapper">
          <li>
            <NavLink
              to="/"
              onClick={() => setMenu('dash')}
              className="sidebar__link"
            >
              <FaHouse />
              <span className="sidebar__link__label">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/boats"
              id="boat"
              onClick={() => setMenu('boat')}
              className="sidebar__link"
            >
              <FaSailboat />
              <span className="sidebar__link__label">Boats</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/people"
              id="people"
              onClick={() => setMenu('people')}
              className="sidebar__link"
            >
              <FaUser />
              <span className="sidebar__link__label">People</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/transactions"
              id="transactions"
              onClick={() => setMenu('transactions')}
              className="sidebar__link"
            >
              <FaMoneyBillTransfer />
              <span className="sidebar__link__label">Transactions</span>
            </NavLink>
          </li>
          <hr className="sidebar__hr" />
          {stateDisplay === 'boat' && (
            <li>
              <NavLink to="/boats/create" className="sidebar__link">
                <IoMdAddCircle />
                <span className="sidebar__link__label">Create</span>
              </NavLink>
            </li>
          )}
        </ul>
        <ul className="sidebar__wrapper">
          <li>
            <NavLink to="/settings" className="sidebar__link">
              <FaGear />
              <span className="sidebar__link__label">Settings</span>
            </NavLink>
          </li>
          <li>
            <Button className="sidebar__link logoutButton" onClick={logout}>
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                <FaSignOutAlt />
              )}
              <span className="sidebar__link__label">Logout</span>
            </Button>
          </li>
        </ul>
      </header>
      <Outlet />
    </>
  );
}
// Export to call it up in app.jsx
export default Sidebar;
