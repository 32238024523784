// import libraries
import React from 'react';
import { Accordion, Row, Form, Col, InputGroup } from 'react-bootstrap';

/*
Boat detail component at creation
@params :
    - data {object} => object values of new boat.
    - setData {function} => change value of new boat object.
    - handleHeader {function} => update value of display body and hidden header of accordion.
    - isDisplay {boolean} => display value of accordion.
    - statusList {array} => array of all status.
    - typologiesList {array} => array of all typology.
    - flagsList {array} => array of all flag.

Display all field to create new boat.
Update values in change.
*/

export default function CreateDetails({
  data,
  setData,
  handleHeader,
  isDisplay,
  statusList,
  typologiesList,
  flagsList,
}) {
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header onClick={() => handleHeader(0)} as="div">
        <section className="accordion__specifications">
          <div className="specifications__info">
            <span className="info__strong">
              {data.brand} - {data.model}
            </span>
            <span className="info__status">
              {data.name && `"${data.name}" - `}Status:{' '}
              {data &&
                statusList.length > 0 &&
                statusList.find((element) => element.id === data.id_status)
                  .value}
            </span>
          </div>
          <hr />
          {isDisplay['0'] && (
            <React.Fragment>
              <Row>
                <span className="col-4">Year: {data.year}</span>
                <span className="col-4">
                  Typology:{' '}
                  {data &&
                    typologiesList.length > 0 &&
                    typologiesList.find(
                      (element) => element.id === data.id_typology,
                    ).value}
                </span>
                <span className="col-4">
                  Flag:{' '}
                  {data &&
                    flagsList.length > 0 &&
                    flagsList.find((element) => element.id === data.id_flag)
                      .value}
                </span>
              </Row>
              <Row>
                <div className="col-4">
                  {data.draft > 0 && (
                    <span className="specifications__measures">
                      Draft:{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.draft / 100)}
                    </span>
                  )}
                  {data.air_draft > 0 && (
                    <span className="specifications__measures">
                      Air-draft:{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.air_draft / 100)}
                    </span>
                  )}
                </div>
                <div className="col-4">
                  <span className="specifications__measures">
                    Length:{' '}
                    {new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'meter',
                    }).format(data.act_length / 100)}
                  </span>
                  {data.act_width > 0 && (
                    <span className="specifications__measures">
                      Width:{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.act_width / 100)}
                    </span>
                  )}
                </div>
                <div className="col-4">
                  {data.adm_length > 0 && (
                    <span className="specifications__measures">
                      Administrative length:{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.adm_length / 100)}
                    </span>
                  )}
                  {data.adm_width > 0 && (
                    <span className="specifications__measures">
                      Administrative width:{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.adm_width / 100)}
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <span className="col-4">
                  {data.headroom &&
                    `Headroom: ${new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'meter',
                    }).format(data.headroom / 100)}`}
                </span>
                <span className="col-4">
                  {data.weight &&
                    `Weight: ${new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'kilogram',
                    }).format(data.weight)}`}
                </span>
                <span className="col-4">
                  {data &&
                    `Tax nav: ${new Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                      maximumFractionDigits: 0,
                    }).format(0)}`}
                </span>
              </Row>
              <hr />
              <div className="specifications__info">
                <span className="info__strong">
                  Price:{' '}
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  }).format(data.price)}
                </span>
              </div>
            </React.Fragment>
          )}
        </section>
      </Accordion.Header>
      <Accordion.Body className="accordion__update">
        <Row className="mb-4">
          <Form.Group as={Col} md={2}>
            <Form.Control
              placeholder="Brand"
              onChange={(e) => setData({ ...data, brand: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              placeholder="Model"
              onChange={(e) => setData({ ...data, model: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Control
              placeholder="Name"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Select
              value={data.id_status}
              onChange={(e) => {
                setData({
                  ...data,
                  id_status: parseInt(e.target.value, 10),
                });
              }}
            >
              {statusList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {element.value}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              Year
            </Form.Label>
            <Form.Control
              placeholder="Year"
              maxLength={4}
              onChange={(e) => {
                setData({
                  ...data,
                  year: e.target.value.replace(/[^0-9]/g, ''),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              value={data.id_typology}
              onChange={(e) => {
                setData({
                  ...data,
                  id_typology: parseInt(e.target.value, 10),
                });
              }}
            >
              {typologiesList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {element.value}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              value={data.id_flag}
              onChange={(e) => {
                setData({ ...data, id_flag: parseInt(e.target.value, 10) });
              }}
            >
              {flagsList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {element.value}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              Draft
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Draft"
                onChange={(e) => {
                  setData({
                    ...data,
                    draft: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              Length
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Length"
                onChange={(e) => {
                  setData({
                    ...data,
                    act_length: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              Admin. Length
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Admin. Length"
                onChange={(e) => {
                  setData({
                    ...data,
                    adm_length: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={3}>
              Air draft
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Air draft"
                onChange={(e) => {
                  setData({
                    ...data,
                    air_draft: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              Width
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Width"
                onChange={(e) => {
                  setData({
                    ...data,
                    act_width: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              Admin. Width
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Admin. Width"
                onChange={(e) => {
                  setData({
                    ...data,
                    adm_width: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={3}>
              Headroom
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Headroom"
                onChange={(e) => {
                  setData({
                    ...data,
                    headroom: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              Weight
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Weight"
                onChange={(e) => {
                  setData({ ...data, weight: e.target.value });
                }}
              />
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              Tax nav
            </Form.Label>
            <InputGroup>
              <Form.Control placeholder="Tax nav" defaultValue={0} readOnly />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} className="update__label">
            <Form.Label>Price</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Price"
                onChange={(e) => {
                  setData({ ...data, price: e.target.value });
                }}
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
