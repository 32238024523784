// import libraries
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Dropdown, Form, Spinner, Table } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
// import utils
import { getDataID } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/transactions.scss';

/*
Transactions page
Display all transactions in progress by user
*/

export default function Transactions() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [status, setStatus] = useState('false');
  const [searchBoat, setSearchBoat] = useState('');
  const [searchLastname, setSearchLastname] = useState('');

  const statusLabels = {
    false: 'In progress',
    true: 'Archived',
    both: 'Both',
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getDataID(
          API_ROUTES.TRANSACTIONS.GET.DETAILS,
          JSON.parse(localStorage.getItem('ids')).user,
        );
        response.data &&
          setCurrentTransactions(response.data.sort((a, b) => b.id - a.id));
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        }
        console.log('transactions index L47 error ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <div className="transactions">
      <h1 className="mb-3 mt-3">Transactions</h1>
      <hr className="w-75" />
      <div className="transactions__filters">
        <div className="filters__modelSearch">
          <Form.Control
            type="text"
            placeholder="Lastname"
            value={searchLastname}
            onChange={(e) => setSearchLastname(e.target.value)}
            className="modelSearch__input"
          />
          <FaSearch className="modelSearch__icon" />
        </div>
        <div className="filters__modelSearch">
          <Form.Control
            type="text"
            placeholder="Model"
            value={searchBoat}
            onChange={(e) => setSearchBoat(e.target.value)}
            className="modelSearch__input"
          />
          <FaSearch className="modelSearch__icon" />
        </div>
        <Dropdown onSelect={(eventKey) => setStatus(eventKey)}>
          <Dropdown.Toggle variant="info" id="dropdown-basic">
            {statusLabels[status]}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="false">In progress</Dropdown.Item>
            <Dropdown.Item eventKey="true">Archived</Dropdown.Item>
            <Dropdown.Item eventKey="both">Both</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Table striped hover className="transactions__table">
          <thead className="table__header">
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Seller</th>
              <th>Boat</th>
              <th>Buyer</th>
              <th>Commission</th>
              <th>Price offer</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {currentTransactions
              .filter((transaction) => {
                if (status === 'both') {
                  return true;
                } else if (status === 'true') {
                  return transaction.status;
                } else {
                  return !transaction.status;
                }
              })
              .filter((item) => {
                return searchBoat.toLowerCase() === ''
                  ? item
                  : item.boat
                      .toLowerCase()
                      .replace(',', '')
                      .replace('.', '')
                      .includes(searchBoat);
              })
              .filter((item) => {
                const sellerNames = item.seller_names.split('/');
                const buyerNames = item.buyer_names.split('/');
                const matchesSeller = sellerNames.some((fullName) =>
                  fullName
                    .split(' ')[0]
                    .toLowerCase()
                    .includes(searchLastname.toLowerCase()),
                );
                const matchesBuyer = buyerNames.some((fullName) =>
                  fullName
                    .split(' ')[0]
                    .toLowerCase()
                    .includes(searchLastname.toLowerCase()),
                );
                return matchesSeller || matchesBuyer;
              })
              .map((element, index) => {
                return (
                  <tr key={index} className="body__row">
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {element.id}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {element.creation_date}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {element.seller_names.replace('/', ', ')}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {element.boat}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {element.buyer_names.replace('/', ', ')}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 0,
                        }).format(element.commission)}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 0,
                        }).format(element.price_offer)}
                      </Link>
                    </td>
                    <td className="row__cell">
                      <Link to={`/transactions/${element.id}`}>
                        <Badge
                          bg={element.status ? 'success' : 'warning'}
                          text={!element.status && 'dark'}
                        >
                          {element.status ? 'Archived' : 'In progess'}
                        </Badge>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </div>
  );
}
