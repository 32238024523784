// import libraries
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
// import css
import '../assets/styles/editButtonGroup.scss';

/*
EditButtonGroup Component
3 buttons to edit, save and cancel any form
*/

export default function EditButtonGroup({
  editIcon,
  editButtonName,
  setIsEditing,
  isEditing,
  valideIcon,
  valideButtonName,
  cancelIcon,
  cancelButtonName,
  form,
  isLoading,
}) {
  return (
    <div className="editGroup">
      <Button
        onClick={() => setIsEditing(true)}
        variant="warning"
        className={isEditing ? 'editButton' : 'editButton editButton-onclick'}
      >
        {editIcon} {editButtonName}
      </Button>
      <div className="editGroup__valideCancelGroup">
        <Button
          onClick={() => setIsEditing(false)}
          type="reset"
          form={form}
          variant="danger"
          disabled={isLoading}
          className={isEditing ? 'editButton editButton-onclick' : 'editButton'}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <React.Fragment>
              {cancelIcon} {cancelButtonName}
            </React.Fragment>
          )}
        </Button>
        <Button
          type="submit"
          form={form}
          variant="success"
          disabled={isLoading}
          className={isEditing ? 'editButton editButton-onclick' : 'editButton'}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <React.Fragment>
              {valideIcon} {valideButtonName}
            </React.Fragment>
          )}
        </Button>
      </div>
    </div>
  );
}
