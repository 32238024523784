// import libraries
import React from 'react'
import { Button } from 'react-bootstrap';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
// import css
import '../assets/styles/validForm.scss';

export default function ValidForm({form, disabled}) {

    return (
        <React.Fragment>
            <Button variant='success'
                type='submit'
                form={form}
                disabled={disabled}
                className='formButtons'>
                <IoMdCheckmarkCircleOutline />
            </Button>
            <Button variant='danger'
                type='reset'
                form={form}
                disabled={disabled}
                className='formButtons'>
                <IoMdCloseCircleOutline />
            </Button>
        </React.Fragment>
    )
}