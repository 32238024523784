// import libraries
import React from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from "react-icons/fa";

/*
Add generator component
@params :
    - generator {object} => new generator to add.
    - deleteItem {function} => delete this generator of array.

All fields needed to create a new generator.
*/

export default function AddGenerator({generator, deleteItem}) {

    return (
        <React.Fragment>
            <Col className='update__canDelete'>
            <span className='accordion__subtitle'>New Generator</span>
            <Button 
                variant='danger' 
                onClick={() => deleteItem(generator.id)}
                className='canDelete__button'>
                <FaRegTrashAlt />
            </Button>
        </Col>
        <Row className='mb-3'>
            <Form.Group as={Col} className='update__label'>
                <Form.Label column md={3}>Brand</Form.Label>
                <Form.Control 
                    placeholder='Brand'
                    onChange={(e) => generator.brand = e.target.value} />
            </Form.Group>
            <Form.Group as={Col} className='update__label'>
                <Form.Label column md={4}>Model</Form.Label>
                <Form.Control 
                    placeholder='Model'
                    onChange={(e) => generator.model = e.target.value} />
            </Form.Group>
        </Row>
        <Row className='mb-3'>
            <Form.Group as={Col} className='update__label'>
                <Form.Label column md={3}>Power</Form.Label>
                <InputGroup>
                    <Form.Control 
                        placeholder='Power'
                        onChange={(e) => generator.power = e.target.value} />
                    <InputGroup.Text>kW</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group as={Col} className='update__label'>
                <Form.Label column md={4}>Hours</Form.Label>
                <InputGroup>
                    <Form.Control 
                        placeholder='Hours'
                        onChange={(e) => generator.hours = e.target.value} />
                    <InputGroup.Text>h</InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </Row>
        <Row className='mb-3'>
            <Form.Group as={Col} className='update__label'>
                <Form.Label column md={2}>Serial number</Form.Label>
                <Form.Control 
                    placeholder='Serial number'
                    onChange={(e) => generator.serial_number = e.target.value} />
            </Form.Group>
        </Row>
        </React.Fragment>
    )
};