// import libraries
import React from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';

/*
Add buyer component
@params :
    - index {integer} => index of buyer in addBuyer.
    - buyer {object} => buyer object to update to fill.
    - dataLeads {array} => array of all leads of boat.
    - handleDelete {function} => function to delete new buyer
    - render {boolean} => boolean to force rerender
    - setRender {function} => function to update render

All fields needed to create a new buyer.
*/

export default function AddBuyer({
  index,
  buyer,
  dataLeads,
  handleDelete,
  render,
  setRender,
}) {
  const handleSelect = (eventKey) => {
    buyer.id_people = eventKey;
    setRender(!render);
  };

  const handleChangeShares = (shares) => {
    buyer.shares = shares;
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mt-2">
        <Dropdown onSelect={handleSelect} className="w-50">
          <Dropdown.Toggle variant="outline-primary" className="w-100">
            {buyer.id_people
              ? `${dataLeads[buyer.id - 1].lastname} ${
                  dataLeads[buyer.id - 1].firstname
                } ${dataLeads[buyer.id - 1].phone}`
              : 'Select buyer'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {dataLeads &&
              dataLeads.map((element, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={element.id}>
                    {element.lastname} {element.firstname}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Group className="w-25">
          <InputGroup>
            <Form.Control
              type="number"
              min={0}
              max={100}
              placeholder="Shares"
              onChange={(e) => handleChangeShares(Number(e.target.value))}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Button size="sm" variant="danger" onClick={() => handleDelete(index)}>
          <FaRegTrashAlt />
        </Button>
      </div>
    </React.Fragment>
  );
}
