// import libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi';
import { FaRegTrashAlt } from 'react-icons/fa';

export default function BoatTable({
  boats,
  addingBoats,
  onDeleteBoat,
  status,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Status</th>
          <th>Year</th>
          <th>Typology</th>
          <th>Price</th>
          {status === 1 && <th>Shares</th>}
          <th>Link</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {boats.length > 0 &&
          boats.map((boat, index) => (
            <tr key={index}>
              <td>{boat.id}</td>
              <td>{boat.brand}</td>
              <td>{boat.model}</td>
              <td>{boat.status}</td>
              <td>{boat.year}</td>
              <td>{boat.typology}</td>
              <td>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                }).format(boat.price)}
              </td>
              {status === 1 && <td>{boat.shares} %</td>}
              <td>
                <Button
                  size="sm"
                  variant="info"
                  as={Link}
                  to={`/boats/${boat.id}`}
                >
                  <FiExternalLink />
                </Button>
              </td>
              <td>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => onDeleteBoat(boat.id)}
                >
                  <FaRegTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        {addingBoats.map((element, index) => (
          <tr key={index}>
            <td>{element.id}</td>
            <td>{element.brand}</td>
            <td>{element.model}</td>
            <td>{element.status}</td>
            <td>{element.year}</td>
            <td>{element.typology}</td>
            <td>
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              }).format(element.price)}
            </td>
            {status === 1 && <td>{element.shares} %</td>}
            <td>
              <Button
                size="sm"
                variant="info"
                as={Link}
                to={`/boats/${element.id}`}
              >
                <FiExternalLink />
              </Button>
            </td>
            <td>
              <Button
                size="sm"
                variant="danger"
                onClick={() => onDeleteBoat(element.id)}
              >
                <FaRegTrashAlt />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
