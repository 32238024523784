export const LOCAL_URL = 'https://auth.boatsandyachts.eu';
const API_URL = 'https://auth.boatsandyachts.eu/auth';
// export const LOCAL_URL = 'http://localhost:3001';
// const API_URL = 'http://localhost:3001/auth';

export const API_ROUTES = {
  TOKEN: {
    REFRESH_TOKEN: `${API_URL}/refreshtoken`,
  },
  BOATS: {
    POST: {
      DETAILS: `${API_URL}/boats/details/:id`,
      IMAGES: `${API_URL}/images/:id`,
      ENGINE: `${API_URL}/boats/:id/engine`,
      MANEUVER_OPTIONS: `${API_URL}/boats/:id/maneuverOptions`,
      BATTERIES: `${API_URL}/boats/:id/battery`,
      GENERATORS: `${API_URL}/boats/:id/generator`,
      SOLAR_PANELS: `${API_URL}/boats/:id/solarPanel`,
      WIND_TURBINES: `${API_URL}/boats/:id/windturbine`,
      ELECTRONICS: `${API_URL}/boats/:id/electronic`,
      TANKS: `${API_URL}/boats/:id/tank`,
      COMFORT: `${API_URL}/boats/:id/comfort`,
      CABINS: `${API_URL}/boats/:id/cabin`,
      KITCHENS: `${API_URL}/boats/:id/kitchen`,
      BATHROOMS: `${API_URL}/boats/:id/bathroom`,
      COVER: `${API_URL}/boats/:id/cover`,
      SAILS: `${API_URL}/boats/:id/sail`,
      RIGGINGS: `${API_URL}/boats/:id/rigging`,
      FITTINGS: `${API_URL}/boats/:id/fittings`,
      SECURITY: `${API_URL}/boats/:id/security`,
      SECURITY_CATEGORY: `${API_URL}/boats/:id/securityCategory`,
      PEOPLE: `${API_URL}/boats/:id/people`,
      EVENTS: `${API_URL}/boats/:id/event`,
    },
    GET: {
      CARDLIST: `${API_URL}/boats/cardList`,
      DETAILS: `${API_URL}/boats/details/:id`,
      IMAGES: `${API_URL}/boats/images/:id`,
      BOAT: `${API_URL}/boats/boat/:id`,
      BRANDS: `${API_URL}/boats/brandList`,
      MODELS: `${API_URL}/boats/modelList`,
      STATUS: `${API_URL}/boats/statusList`,
      TYPOLOGIES: `${API_URL}/boats/typologyList`,
      FLAGS: `${API_URL}/boats/flagList`,
      ENGINES_DISPLAY: `${API_URL}/boats/engines/:id`,
      ENGINES_UPDATE: `${API_URL}/boats/:id/engines`,
      TRANSMISSIONS: `${API_URL}/boats/transmissionList`,
      MANEUVER_OPTIONS: `${API_URL}/boats/maneuverOptions/:id`,
      BATTERIES: `${API_URL}/boats/batteryPark/:id`,
      GENERATORS: `${API_URL}/boats/generators/:id`,
      SOLAR_PANELS: `${API_URL}/boats/solarPanel/:id`,
      WIND_TURBINES: `${API_URL}/boats/windturbine/:id`,
      ELECTRONICS: `${API_URL}/boats/electronic/:id`,
      TANKS_DISPLAY: `${API_URL}/boats/tanks/:id`,
      TANKS_UPDATE: `${API_URL}/boats/:id/tanks`,
      TANKS_TYPES: `${API_URL}/boats/tanksTypes`,
      MATERIALS: `${API_URL}/boats/material`,
      COMFORT: `${API_URL}/boats/comfort/:id`,
      CABINS_DISPLAY: `${API_URL}/boats/cabins/:id`,
      CABINS_UPDATE: `${API_URL}/boats/:id/cabins`,
      CABINS_TYPES: `${API_URL}/boats/cabinsTypes`,
      POSITIONS: `${API_URL}/boats/position`,
      KITCHENS_DISPLAY: `${API_URL}/boats/kitchens/:id`,
      KITCHENS_UPDATE: `${API_URL}/boats/:id/kitchens`,
      HOTPLATES: `${API_URL}/boats/hotplateType`,
      OVENS: `${API_URL}/boats/ovenType`,
      BATHROOMS_DISPLAY: `${API_URL}/boats/bathroom/:id`,
      BATHROOMS_UPDATE: `${API_URL}/boats/:id/bathroom`,
      COVER: `${API_URL}/boats/cover/:id`,
      SAILS_DISPLAY: `${API_URL}/boats/sails/:id`,
      SAILS_UPDATE: `${API_URL}/boats/:id/sails`,
      SAILS_TYPES: `${API_URL}/boats/sailsTypes`,
      RIGGING: `${API_URL}/boats/rigging/:id`,
      FITTINGS: `${API_URL}/boats/fittings/:id`,
      SECURITY: `${API_URL}/boats/security/:id`,
      SECURITY_CATEGORY_DISPLAY: `${API_URL}/boats/securityCategory`,
      SECURITY_CATEGORY_UPDATE: `${API_URL}/boats/securityCategory/:id`,
      SECURITY_CERTIFICATION: `${API_URL}/boats/:id/securityCategory`,
      SECURITY_EQUIPMENT: `${API_URL}/boats/securityEquipment`,
      SECURITY_BOAT: `${API_URL}/boats/:id1/security/:id2`,
      PEOPLE: `${API_URL}/boats/:id/people`,
      PERSON: `${API_URL}/boats/:id/person`,
      SEARCH_PEOPLE: `${API_URL}/boats/people`,
      TRANSACTION: `${API_URL}/boats/:id/transactions`,
      EVENTS: `${API_URL}/boats/:id/event`,
    },
    PUT: {
      IMAGES: `${API_URL}/images/:id`,
      DETAILS: `${API_URL}/boats/:id/boat`,
      ENGINE: `${API_URL}/boats/:id/engine`,
      MANEUVER_OPTIONS: `${API_URL}/boats/:id/maneuverOptions`,
      BATTERIES: `${API_URL}/boats/:id/battery`,
      GENERATORS: `${API_URL}/boats/:id/generator`,
      SOLAR_PANELS: `${API_URL}/boats/:id/solarPanel`,
      WIND_TURBINES: `${API_URL}/boats/:id/windturbine`,
      ELECTRONICS: `${API_URL}/boats/:id/electronic`,
      TANKS: `${API_URL}/boats/:id/tank`,
      COMFORT: `${API_URL}/boats/:id/comfort`,
      CABINS: `${API_URL}/boats/:id/cabin`,
      KITCHENS: `${API_URL}/boats/:id/kitchen`,
      BATHROOMS: `${API_URL}/boats/:id/bathroom`,
      COVER: `${API_URL}/boats/:id/cover`,
      SAILS: `${API_URL}/boats/:id/sail`,
      RIGGINGS: `${API_URL}/boats/:id/rigging`,
      FITTINGS: `${API_URL}/boats/:id/fittings`,
      SECURITY: `${API_URL}/boats/:id/security`,
      SECURITY_CATEGORY: `${API_URL}/boats/:id/securityCategory`,
      PEOPLE: `${API_URL}/boats/:id/people`,
    },
    DELETE: {
      IMAGES: `${API_URL}/images/:id`,
      ENGINE: `${API_URL}/boats/:id/engine`,
      BATTERIES: `${API_URL}/boats/:id/battery`,
      GENERATORS: `${API_URL}/boats/:id/generator`,
      TANKS: `${API_URL}/boats/:id/tank`,
      CABINS: `${API_URL}/boats/:id/cabin`,
      KITCHENS: `${API_URL}/boats/:id/kitchen`,
      BATHROOMS: `${API_URL}/boats/:id/bathroom`,
      SAILS: `${API_URL}/boats/:id/sail`,
      SECURITY_CATEGORY: `${API_URL}/boats/:id/securityCategory`,
      PEOPLE: `${API_URL}/boats/:id/people`,
    },
  },
  PEOPLE: {
    POST: {
      PEOPLE: `${API_URL}/people`,
      BOAT: `${API_URL}/people/:id/boat`,
    },
    GET: {
      TITLE: `${API_URL}/people/title`,
      CHECK_SEARCH: `${API_URL}/people/check/search`,
      SEARCH: `${API_URL}/people/search`,
      PERSON: `${API_URL}/people/:id`,
      STATUS: `${API_URL}/people/status`,
      BOATS: `${API_URL}/people/:id/boats`,
    },
    PUT: {
      PERSON: `${API_URL}/people/:id`,
      BOATS: `${API_URL}/people/boats`,
    },
    DELETE: {
      BOAT: `${API_URL}/people/:id`,
    },
  },
  TRANSACTIONS: {
    POST: {
      CREATE: `${API_URL}/transactions/:id`,
      BUYER: `${API_URL}/transactions/:id/buyers`,
    },
    GET: {
      ALL: `${API_URL}/transactions`,
      WITHID: `${API_URL}/transactions/:id1/:id2`,
      DETAILS: `${API_URL}/transactions/details/:id`,
      PEOPLE: `${API_URL}/transactions/:id/people`,
    },
    PUT: {
      UPDATE: `${API_URL}/transactions/:id`,
      SHARES: `${API_URL}/transactions/shares`,
    },
    DELETE: {
      TRANSACTION: `${API_URL}/transactions/:id`,
    },
  },
  USERS: {
    POST: {
      LOGIN: `${API_URL}/login`,
    },
    GET: {
      AGENCY: `${API_URL}/user/allAgency`,
      DASHBOARD: `${API_URL}/user/dashboard/`,
    },
  },
};
