// import libraries
import React from 'react'
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { FaRegTrashAlt } from "react-icons/fa";

/*
Add sail component
@params :
    - sailsTypes {array of objects} => all sailsTypes possible.
    - sail {object} => new sail to add.
    - deleteItem {function} => delete this sail of array.

All fields needed to create a new sail.
*/

export default function AddSail({sailsTypes, sail, deleteItem}) {

    return (
        <React.Fragment>
            <Col className='update__canDelete'>
                <span className='accordion__subtitle'>New Sail</span>
                <Button 
                    variant='danger' 
                    onClick={() => deleteItem(sail.id)}
                    className='canDelete__button'>
                    <FaRegTrashAlt />
                </Button>
            </Col>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={2}>Type</Form.Label>
                    <Form.Select
                        onChange={(e) => sail.id_sail_type = e.target.value}>
                        {sailsTypes.map((element, index) => {
                            return (
                                <option key={index} value={element.id}>{element.value}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={2}>Brand</Form.Label>
                    <Form.Control 
                        placeholder='Brand'
                        onChange={(e) => sail.brand = e.target.value} />
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={2}>Area</Form.Label>
                    <InputGroup>
                        <Form.Control 
                            placeholder='Area'
                            onChange={(e) => sail.area = e.target.value} />
                        <InputGroup.Text>m²</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} md={2} className='update__label'>
                    <Form.Label column md={4}>Year</Form.Label>
                    <Form.Control 
                        placeholder='Year'
                        onChange={(e) => sail.year = e.target.value} />
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column>Description</Form.Label>
                    <Form.Control 
                        placeholder='Description'
                        onChange={(e) => sail.description = e.target.value} />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
};