// import libraries
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Toast,
  ToastContainer,
  Table,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoDuplicate } from 'react-icons/io5';
import { FiExternalLink } from 'react-icons/fi';
import { MdAddBox } from 'react-icons/md';
// import components
import Carousel from '../../components/carousel';
import AddEngine from '../../components/addEngine';
import AddBattery from '../../components/addBattery';
import AddGenerator from '../../components/addGenerator';
import AddTank from '../../components/addTank';
import AddCabin from '../../components/addCabin';
import AddKitchen from '../../components/addKitchen';
import AddBathroom from '../../components/addBathroom';
import AddSail from '../../components/addSail';
// import utils
import { dafn } from '../../utils/dafn';
import { API_ROUTES } from '../../utils/constants';
import {
  deleteDataID,
  getData,
  getDataID,
  getDataID2,
  getDataParams,
  postDataID,
  putDataID,
} from '../../utils/api';
// import css
import '../../assets/styles/boat.scss';
import '../../assets/styles/customDropdown.scss';

/*
Boat details page
Display all images and details of a boat fetch by his id.
Each informations can be modify.
*/

export default function BoatDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const [enginesDisplay, setEnginesDisplay] = useState([]);
  const [maneuverOptions, setManeuverOptions] = useState();
  const [batteries, setBatteries] = useState([]);
  const [generators, setGenerators] = useState([]);
  const [solarPanel, setSolarPanel] = useState();
  const [windturbine, setWindturbine] = useState();
  const [electronic, setElectronic] = useState();
  const [tanks, setTanks] = useState([]);
  const [comfort, setComfort] = useState();
  const [cabinsDisplay, setCabinsDisplay] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [cover, setCover] = useState();
  const [sails, setSails] = useState([]);
  const [rigging, setRigging] = useState();
  const [fittings, setFittings] = useState();
  const [security, setSecurity] = useState();
  const [securityCategory, setSecurityCategory] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response0 = await getDataID(API_ROUTES.BOATS.GET.DETAILS, id);
        setDetails(response0.data.detail);
        setUser(response0.data.modify);
        const response1 = await getDataID(
          API_ROUTES.BOATS.GET.ENGINES_DISPLAY,
          id,
        );
        setEnginesDisplay(response1.data);
        const response2 = await getDataID(API_ROUTES.BOATS.GET.BATTERIES, id);
        setBatteries(response2.data);
        setBatteriesUpdate(response2.data);
        const response3 = await getDataID(API_ROUTES.BOATS.GET.GENERATORS, id);
        setGenerators(response3.data);
        setGeneratorsUpdate(response3.data);
        const response4 = await getDataID(
          API_ROUTES.BOATS.GET.TANKS_DISPLAY,
          id,
        );
        setTanks(response4.data);
        const response5 = await getDataID(
          API_ROUTES.BOATS.GET.CABINS_DISPLAY,
          id,
        );
        setCabinsDisplay(response5.data);
        const response6 = await getDataID(
          API_ROUTES.BOATS.GET.KITCHENS_DISPLAY,
          id,
        );
        setKitchens(response6.data);
        const response7 = await getDataID(
          API_ROUTES.BOATS.GET.BATHROOMS_DISPLAY,
          id,
        );
        setBathroom(response7.data);
        const response8 = await getDataID(
          API_ROUTES.BOATS.GET.SAILS_DISPLAY,
          id,
        );
        setSails(response8.data);
        const response9 = await getDataID(
          API_ROUTES.BOATS.GET.SECURITY_CATEGORY_UPDATE,
          id,
        );
        setSecurityCategory(response9.data);
      } catch (error) {
        console.log('boat index L110 error ', error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (details) {
      try {
        const fetchData = async () => {
          if (details.id_maneuver_options) {
            const response0 = await getDataID(
              API_ROUTES.BOATS.GET.MANEUVER_OPTIONS,
              details.id_maneuver_options,
            );
            setManeuverOptions(response0.data);
            setManeuverOptionsUpdate(response0.data);
          }
          if (details.id_solar_panel) {
            const response1 = await getDataID(
              API_ROUTES.BOATS.GET.SOLAR_PANELS,
              details.id_solar_panel,
            );
            setSolarPanel(response1.data);
            setSolarPanelUpdate(response1.data);
          }
          if (details.id_windturbine) {
            const response2 = await getDataID(
              API_ROUTES.BOATS.GET.WIND_TURBINES,
              details.id_windturbine,
            );
            setWindturbine(response2.data);
            setWindturbineUpdate(response2.data);
          }
          if (details.id_electronic) {
            const response3 = await getDataID(
              API_ROUTES.BOATS.GET.ELECTRONICS,
              details.id_electronic,
            );
            setElectronic(response3.data);
            setElectronicUpdate(response3.data);
          }
          if (details.id_comfort) {
            const response4 = await getDataID(
              API_ROUTES.BOATS.GET.COMFORT,
              details.id_comfort,
            );
            setComfort(response4.data);
            setComfortUpdate(response4.data);
          }
          if (details.id_cover) {
            const response5 = await getDataID(
              API_ROUTES.BOATS.GET.COVER,
              details.id_cover,
            );
            setCover(response5.data);
            setCoverUpdate(response5.data);
          }
          if (details.id_rigging) {
            const response6 = await getDataID(
              API_ROUTES.BOATS.GET.RIGGING,
              details.id_rigging,
            );
            setRigging(response6.data);
            setRiggingUpdate(response6.data);
          }
          if (details.id_fittings) {
            const response7 = await getDataID(
              API_ROUTES.BOATS.GET.FITTINGS,
              details.id_fittings,
            );
            setFittings(response7.data);
            setFittingsUpdate(response7.data);
          }
          if (details.id_security) {
            const response8 = await getDataID(
              API_ROUTES.BOATS.GET.SECURITY,
              details.id_security,
            );
            setSecurity(response8.data);
          }
        };
        fetchData();
      } catch (error) {
        console.log('boat index L213 error', error);
      }
    }
    // eslint-disable-next-line
  }, [details]);

  const [user, setUser] = useState(false);
  const [boat, setBoat] = useState();
  const [boatUpdate, setBoatUpdate] = useState();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [status, setStatus] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [flags, setFlags] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [enginesDefault, setEnginesDefault] = useState([]);
  const [enginesUpdate, setEnginesUpdate] = useState([]);
  const [addEngine, setAddEngine] = useState([]);
  const [deleteEngines, setDeleteEngines] = useState([]);
  const [maneuverOptionsUpdate, setManeuverOptionsUpdate] = useState();
  const [batteriesUpdate, setBatteriesUpdate] = useState([]);
  const [addBattery, setAddBattery] = useState([]);
  const [deleteBatteries, setDeleteBatteries] = useState([]);
  const [generatorsUpdate, setGeneratorsUpdate] = useState([]);
  const [addGenerator, setAddGenerator] = useState([]);
  const [deleteGenerators, setDeleteGenerators] = useState([]);
  const [solarPanelUpdate, setSolarPanelUpdate] = useState();
  const [windturbineUpdate, setWindturbineUpdate] = useState();
  const [electronicUpdate, setElectronicUpdate] = useState();
  const [tanksDefault, setTanksDefault] = useState([]);
  const [tanksUpdate, setTanksUpdate] = useState([]);
  const [addTanks, setAddTanks] = useState([]);
  const [deleteTanks, setDeleteTanks] = useState([]);
  const [tanksTypes, setTanksTypes] = useState([]);
  const [material, setMaterial] = useState([]);
  const [comfortUpdate, setComfortUpdate] = useState();
  const [cabinsDefault, setCabinsDefault] = useState([]);
  const [cabinsUpdate, setCabinsUpdate] = useState([]);
  const [addCabins, setAddCabins] = useState([]);
  const [deleteCabins, setDeleteCabins] = useState([]);
  const [cabinsTypes, setCabinsTypes] = useState([]);
  const [position, setPosition] = useState([]);
  const [kitchensDefault, setKitchensDefault] = useState([]);
  const [kitchensUpdate, setKitchensUpdate] = useState([]);
  const [addKitchens, setAddKitchens] = useState([]);
  const [deleteKitchens, setDeleteKitchens] = useState([]);
  const [hotplates, setHotplates] = useState([]);
  const [oven, setOven] = useState([]);
  const [bathroomDefault, setBathroomDefault] = useState([]);
  const [bathroomUpdate, setBathroomUpdate] = useState([]);
  const [addBathrooms, setAddBathrooms] = useState([]);
  const [deleteBathrooms, setDeleteBathrooms] = useState([]);
  const [coverUpdate, setCoverUpdate] = useState();
  const [sailsDefault, setSailsDefault] = useState([]);
  const [sailsUpdate, setSailsUpdate] = useState([]);
  const [addSails, setAddSails] = useState([]);
  const [deleteSails, setDeleteSails] = useState([]);
  const [sailsTypes, setSailsTypes] = useState([]);
  const [riggingUpdate, setRiggingUpdate] = useState();
  const [fittingsUpdate, setFittingsUpdate] = useState();
  const [securityDefault, setSecurityDefault] = useState();
  const [securityUpdate, setSecurityUpdate] = useState();
  const [securityEquipment, setSecurityEquipment] = useState([]);
  const [securityCertifDefault, setSecurityCertifDefault] = useState([]);
  const [securityCertifUpdate, setSecurityCertifUpdate] = useState([]);
  const [securityCategoryList, setSecurityCategoryList] = useState([]);
  const [addCertif, setAddCertif] = useState([]);
  const [delCertif, setDelCertif] = useState([]);
  const [people, setPeople] = useState([]);
  const [peopleDefault, setPeopleDefault] = useState([]);
  const [person, setPerson] = useState([]);
  const [personDefault, setPersonDefault] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [statusPeople, setStatusPeople] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [shares, setShares] = useState();
  const [addingOwner, setAddingOwner] = useState([]);
  const [addingLead, setAddingLead] = useState([]);
  const [deletedPeople, setDeletedPeople] = useState([]);
  const [transaction, setTransaction] = useState();
  const [transactionUpdate, setTransactionUpdate] = useState();
  const [commissionMoney, setCommissionMoney] = useState();
  const [commissionPercentage, setCommissionPercentage] = useState();
  const [buyerList, setBuyerList] = useState([]);
  const [tracking, setTracking] = useState([]);

  // update engines field and set new values
  const updateEnginesField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(enginesUpdate));
    Arr[index][`${field}`] = e.target.value;
    setEnginesUpdate(Arr);
  };

  const deleteEngine = (id) => {
    setDeleteEngines([...deleteEngines, id]);
    const Arr = JSON.parse(JSON.stringify(enginesUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setEnginesUpdate(Arr);
  };

  const addNewEngine = () => {
    const newEngine = {
      id: addEngine.length + 1,
      brand: '',
      model: '',
      power: 0,
      fiscal_power: 0,
      id_transmission: 1,
      hours: 0,
      serial_number: '',
    };
    setAddEngine([...addEngine, newEngine]);
  };

  const deleteObjectEngine = (id) => {
    const Arr = JSON.parse(JSON.stringify(addEngine));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddEngine(Arr);
  };

  const duplicateEngine = (id) => {
    const duplicate = enginesUpdate.find((element) => element.id === id);
    const clonedDuplicate = JSON.parse(JSON.stringify(duplicate));
    setAddEngine([...addEngine, clonedDuplicate]);
  };

  const duplicateNewEngine = (id) => {
    const duplicateNew = addEngine[id - 1];
    setAddEngine([...addEngine, duplicateNew]);
  };

  // update battery park field and set new values
  const updateBatteryField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(batteriesUpdate));
    Arr[index][`${field}`] = e.target.value;
    setBatteriesUpdate(Arr);
  };

  const addNewBattery = () => {
    const newBattery = {
      id: addBattery.length + 1,
      quantity: 0,
      voltage: 0,
      amperage: 0,
      assignment: '',
      type: '',
    };
    setAddBattery([...addBattery, newBattery]);
  };

  const deleteObjectBattery = (id) => {
    const Arr = JSON.parse(JSON.stringify(addBattery));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddBattery(Arr);
  };

  const deleteBattery = (id) => {
    setDeleteBatteries([...deleteBatteries, id]);
    const Arr = JSON.parse(JSON.stringify(batteriesUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setBatteriesUpdate(Arr);
  };

  // update generators field and set new values
  const updateGeneratorField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(generatorsUpdate));
    Arr[index][`${field}`] = e.target.value;
    setGeneratorsUpdate(Arr);
  };

  const addNewGenerator = () => {
    const newGenerator = {
      id: addGenerator.length + 1,
      brand: '',
      model: '',
      power: 0,
      hours: 0,
      serial_number: '',
    };
    setAddGenerator([...addGenerator, newGenerator]);
  };

  const deleteObjectGenerator = (id) => {
    const Arr = JSON.parse(JSON.stringify(addGenerator));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddGenerator(Arr);
  };

  const deleteGenerator = (id) => {
    setDeleteGenerators([...deleteGenerators, id]);
    const Arr = JSON.parse(JSON.stringify(generatorsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setGeneratorsUpdate(Arr);
  };

  // update tanks field and set new values
  const updateTanksField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(tanksUpdate));
    if (field === 'id_material' && e.target.value === '0') {
      Arr[index].id_material = null;
    } else {
      Arr[index][`${field}`] = e.target.value;
      setTanksUpdate(Arr);
    }
  };

  const addNewTank = () => {
    const newTank = {
      id: addTanks.length + 1,
      capacity: 0,
      id_tanks_type: 1,
      id_material: null,
    };
    setAddTanks([...addTanks, newTank]);
  };

  const deleteObjectTank = (id) => {
    const Arr = JSON.parse(JSON.stringify(addTanks));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddTanks(Arr);
  };

  const deleteTank = (id) => {
    setDeleteTanks([...deleteTanks, id]);
    const Arr = JSON.parse(JSON.stringify(tanksUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setTanksUpdate(Arr);
  };

  // update cabins field and set new values
  const updateCabinsField = (e, index, field) => {
    if (e.target.checked !== undefined && field !== 'quantity_single_bed') {
      const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
      Arr[index][`${field}`] = e.target.checked;
      setCabinsUpdate(Arr);
    } else {
      const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
      Arr[index][`${field}`] = e.target.value;
      setCabinsUpdate(Arr);
    }
  };

  const addNewCabin = () => {
    const newCabin = {
      id: addCabins.length + 1,
      id_cabin_type: 1,
      id_position: 1,
      quantity_single_bed: 0,
      double_bed: false,
      double_bed_description: null,
      air_conditioning: false,
      pulse_air_heating: false,
      hifi: false,
      hifi_description: null,
      tv: false,
      tv_description: null,
    };
    setAddCabins([...addCabins, newCabin]);
  };

  const deleteObjectCabin = (id) => {
    const Arr = JSON.parse(JSON.stringify(addCabins));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddCabins(Arr);
  };

  const deleteCabin = (id) => {
    setDeleteCabins([...deleteCabins, id]);
    const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setCabinsUpdate(Arr);
  };

  // update kitchens field and set new values
  const updateKitchensField = (e, index, field) => {
    if (field === 'microwave') {
      const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
      Arr[index][`${field}`] = e.target.checked;
      setKitchensUpdate(Arr);
    } else {
      const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
      Arr[index][`${field}`] = e.target.value;
      setKitchensUpdate(Arr);
    }
  };

  const addNewKitchen = () => {
    const newKitchen = {
      id: addKitchens.length + 1,
      id_hotplates: null,
      hotplates_description: '',
      quantity_oven: 0,
      id_oven: null,
      microwave: false,
      quantity_fridge: 0,
      quantity_freezer: 0,
      description: '',
    };
    setAddKitchens([...addKitchens, newKitchen]);
  };

  const deleteObjectKitchen = (id) => {
    const Arr = JSON.parse(JSON.stringify(addKitchens));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddKitchens(Arr);
  };

  const deleteKitchen = (id) => {
    setDeleteKitchens([...deleteKitchens, id]);
    const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setKitchensUpdate(Arr);
  };

  // update bathrooms field and set new values
  const updateBathroomField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(bathroomUpdate));
    Arr[index][`${field}`] = e.target.value;
    setBathroomUpdate(Arr);
  };

  const addNewBathroom = () => {
    const newBathroom = {
      id: addBathrooms.length + 1,
      id_position: 1,
      description: '',
      toilets: '',
      shower: '',
      bathtub: '',
    };
    setAddBathrooms([...addBathrooms, newBathroom]);
  };

  const deleteObjectBathroom = (id) => {
    const Arr = JSON.parse(JSON.stringify(addBathrooms));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddBathrooms(Arr);
  };

  const deleteBathroom = (id) => {
    setDeleteBathrooms([...deleteBathrooms, id]);
    const Arr = JSON.parse(JSON.stringify(bathroomUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setBathroomUpdate(Arr);
  };

  // update sails field and set new values
  const updateSailsField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(sailsUpdate));
    Arr[index][`${field}`] = e.target.value;
    setSailsUpdate(Arr);
  };

  const addNewSail = () => {
    const newSail = {
      id: addSails.length + 1,
      id_sail_type: 1,
      brand: null,
      area: null,
      year: null,
      description: null,
    };
    setAddSails([...addSails, newSail]);
  };

  const deleteObjectSail = (id) => {
    const Arr = JSON.parse(JSON.stringify(addSails));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddSails(Arr);
  };

  const deleteSail = (id) => {
    setDeleteSails([...deleteSails, id]);
    const Arr = JSON.parse(JSON.stringify(sailsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setSailsUpdate(Arr);
  };

  // update catgory field and set new values
  const updateCategoryField = (index, value) => {
    if (value === '') {
      setDelCertif([...delCertif, index]);
      const Arr = JSON.parse(JSON.stringify(securityCertifUpdate));
      const ind = securityCertifUpdate.indexOf(
        securityCertifUpdate.find((item) => item.id_category === index),
      );
      Arr.splice(ind, 1);
      setSecurityCertifUpdate(Arr);
    } else if (
      securityCertifUpdate.find((item) => item.id_category === index)
    ) {
      const Arr = JSON.parse(JSON.stringify(securityCertifUpdate));
      Arr[
        securityCertifUpdate.indexOf(
          securityCertifUpdate.find((item) => item.id_category === index),
        )
      ].quantity_people = value;
      setSecurityCertifUpdate(Arr);
    } else {
      if (addCertif.find((item) => item.id_category === index)) {
        const Arr = JSON.parse(JSON.stringify(addCertif));
        Arr[
          addCertif.indexOf(
            addCertif.find((item) => item.id_category === index),
          )
        ].quantity_people = value;
        setAddCertif(Arr);
      } else {
        const newCat = {
          id: addCertif.length + 1,
          id_boats: id,
          id_category: index,
          quantity_people: value,
        };
        setAddCertif([...addCertif, newCat]);
      }
    }
  };

  const compareArrays = (a, b) => {
    if (a && b) {
      return (
        a.length === b.length &&
        a.every((element, index) => compareObjects(element, b[index]))
      );
    } else {
      return true;
    }
  };

  const compareObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const isButtonDisabled = () => {
    if (!selectedItem) {
      return true;
    }
    if (selectedStatus === '1') {
      return !(shares >= 1 && shares <= 100);
    }
    if (selectedStatus === '2') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const response = await getDataID(API_ROUTES.BOATS.GET.BOAT, id);
          setBoat({
            ...response.data,
            model: details.model,
            brand: details.brand,
          });
          setBoatUpdate({
            ...response.data,
            model: details.model,
            brand: details.brand,
          });
          const response0 = await getData(API_ROUTES.BOATS.GET.BRANDS);
          setBrands(response0.data);
          const response1 = await getData(API_ROUTES.BOATS.GET.MODELS);
          setModels(response1.data);
          const response2 = await getData(API_ROUTES.BOATS.GET.STATUS);
          setStatus(response2.data);
          const response3 = await getData(API_ROUTES.BOATS.GET.TYPOLOGIES);
          setTypologies(response3.data);
          const response4 = await getData(API_ROUTES.BOATS.GET.FLAGS);
          setFlags(response4.data);
          const response5 = await getData(API_ROUTES.BOATS.GET.TRANSMISSIONS);
          setTransmission(response5.data.sort((a, b) => a.id - b.id));
          const response6 = await getDataID(
            API_ROUTES.BOATS.GET.ENGINES_UPDATE,
            id,
          );
          setEnginesDefault(response6.data);
          setEnginesUpdate(response6.data);
          const response7 = await getDataID(
            API_ROUTES.BOATS.GET.TANKS_UPDATE,
            id,
          );
          setTanksDefault(response7.data);
          setTanksUpdate(response7.data);
          const response8 = await getData(API_ROUTES.BOATS.GET.TANKS_TYPES);
          setTanksTypes(response8.data);
          const response9 = await getData(API_ROUTES.BOATS.GET.MATERIALS);
          setMaterial(response9.data);
          const response10 = await getDataID(
            API_ROUTES.BOATS.GET.CABINS_UPDATE,
            id,
          );
          setCabinsDefault(response10.data);
          setCabinsUpdate(response10.data);
          const response11 = await getData(API_ROUTES.BOATS.GET.CABINS_TYPES);
          setCabinsTypes(response11.data);
          const response12 = await getData(API_ROUTES.BOATS.GET.POSITIONS);
          setPosition(response12.data);
          const response13 = await getDataID(
            API_ROUTES.BOATS.GET.KITCHENS_UPDATE,
            id,
          );
          setKitchensDefault(response13.data);
          setKitchensUpdate(response13.data);
          const response14 = await getData(API_ROUTES.BOATS.GET.HOTPLATES);
          setHotplates(response14.data.sort((a, b) => a.id - b.id));
          const response15 = await getData(API_ROUTES.BOATS.GET.OVENS);
          setOven(response15.data.sort((a, b) => a.id - b.id));
          const response16 = await getDataID(
            API_ROUTES.BOATS.GET.BATHROOMS_UPDATE,
            id,
          );
          setBathroomDefault(response16.data);
          setBathroomUpdate(response16.data);
          const response17 = await getDataID(
            API_ROUTES.BOATS.GET.SAILS_UPDATE,
            id,
          );
          setSailsDefault(response17.data);
          setSailsUpdate(response17.data);
          const response18 = await getData(API_ROUTES.BOATS.GET.SAILS_TYPES);
          setSailsTypes(response18.data.sort((a, b) => a.id - b.id));
          const response19 = await getDataID(
            API_ROUTES.BOATS.GET.SECURITY_CERTIFICATION,
            id,
          );
          setSecurityCertifDefault(response19.data);
          setSecurityCertifUpdate(response19.data);
          const response20 = await getData(
            API_ROUTES.BOATS.GET.SECURITY_CATEGORY_DISPLAY,
          );
          setSecurityCategoryList(response20.data);
          const getPeople = await getDataID(API_ROUTES.BOATS.GET.PEOPLE, id);
          setPeople(getPeople.data);
          setPeopleDefault(getPeople.data);
          const getPerson = await getDataID(API_ROUTES.BOATS.GET.PERSON, id);
          setPerson(getPerson.data);
          setPersonDefault(getPerson.data);
          const statusData = await getData(API_ROUTES.PEOPLE.GET.STATUS);
          setStatusPeople(statusData.data);
          const boatEvent = await getDataID(API_ROUTES.BOATS.GET.EVENTS, id);
          setTracking(boatEvent.data);
        } catch (error) {
          console.log('index boat L710 error', error);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && boat) {
      try {
        const fetchData = async () => {
          if (boat.id_security) {
            const response0 = await getDataID2(
              API_ROUTES.BOATS.GET.SECURITY_BOAT,
              id,
              boat.id_security,
            );
            setSecurityDefault(response0.data);
            setSecurityUpdate(response0.data);
          }
          const response = await getData(
            API_ROUTES.BOATS.GET.SECURITY_EQUIPMENT,
          );
          setSecurityEquipment(response.data);
          if (boat.id_status === 8) {
            const transaction = await getDataID(
              API_ROUTES.BOATS.GET.TRANSACTION,
              id,
            );
            setTransaction(transaction.data[0]);
            setTransactionUpdate(transaction.data[0]);
            setCommissionMoney(transaction.data[0].commission);
            setCommissionPercentage(
              (
                (transaction.data[0].commission /
                  transaction.data[0].price_offer) *
                100
              )
                .toFixed(4)
                .toString()
                .replace(/\.00$/, ''),
            );
            if (transaction.data[0].status === 0) {
              const buyers = await getDataID(
                API_ROUTES.TRANSACTIONS.GET.PEOPLE,
                transaction.data[0].id,
              );
              const filteredData = buyers.data
                .filter((item) => item.status_people === 1)
                .map((item) => ({
                  id: item.id_people,
                  shares: item.shares,
                }));
              setBuyerList(filteredData);
            }
          }
        };
        fetchData();
      } catch (error) {
        console.log('index boat L800 error ', error);
      }
    }
    // eslint-disable-next-line
  }, [user, boat]);

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const CustomMenu = React.forwardRef(
    (
      {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
        onSelectItem,
      },
      ref,
    ) => {
      const [value, setValue] = useState('');
      const [results, setResults] = useState([]);
      const typingTimeout = useRef(null);

      const handleSearch = async (query) => {
        try {
          const params = new URLSearchParams();
          params.append('lastname', query);
          const response = await getDataParams(
            API_ROUTES.BOATS.GET.SEARCH_PEOPLE,
            params,
          );
          setResults(response.data);
        } catch (error) {
          console.error('Error fetching search results', error);
        }
      };

      const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);

        if (typingTimeout.current) {
          clearTimeout(typingTimeout.current);
        }

        typingTimeout.current = setTimeout(() => {
          if (newValue.trim()) {
            handleSearch(newValue);
          } else {
            setResults([]);
          }
        }, 300);
      };

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={handleChange}
            value={value}
          />
          <ul className="list-unstyled">
            {results.length > 0 ? (
              results.map((result) => (
                <Dropdown.Item
                  key={result.id}
                  eventKey={result.id}
                  onClick={() => onSelectItem(result)}
                >
                  {result.firstname} {result.lastname}, {result.company} -{' '}
                  {result.email} - {result.phone}
                </Dropdown.Item>
              ))
            ) : (
              <li className="px-3 py-2 text-muted">No results found</li>
            )}
          </ul>
        </div>
      );
    },
  );

  const handleAddPeople = () => {
    if (!selectedItem) {
      return;
    }

    if (selectedStatus === '1' && shares >= 0 && shares <= 100) {
      const newOwner = {
        ...selectedItem,
        statusPeople: selectedStatus,
        shares: shares,
        contact: 0,
        commission: 1000,
      };
      setAddingOwner((prev) => [...prev, newOwner]);
    }
    if (selectedStatus === '2') {
      const newLead = { ...selectedItem, statusPeople: selectedStatus };
      setAddingLead((prev) => [...prev, newLead]);
    }
    setSelectedItem(null);
    setShares();
  };

  const handleDeletePeople = (id) => {
    if (addingLead.some((element) => element.id === id)) {
      setAddingLead((prev) => prev.filter((element) => element.id !== id));
    } else if (addingOwner.some((element) => element.id === id)) {
      setAddingOwner((prev) => prev.filter((element) => element.id !== id));
    } else {
      setDeletedPeople((prev) => [...prev, id]);
      setPerson((prev) => prev.filter((element) => element.id !== id));
    }
  };

  const handleUpdatePeople = (id, field, value) => {
    if (addingOwner.some((element) => element.id === id)) {
      if (field === 'commission') {
        setAddingOwner((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id === id ? { ...pers, [field]: value * 100 } : pers,
          ),
        );
      } else {
        setAddingOwner((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id === id ? { ...pers, [field]: value } : pers,
          ),
        );
      }
    } else {
      if (field === 'commission') {
        setPeople((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id_people === id ? { ...pers, [field]: value * 100 } : pers,
          ),
        );
      } else {
        setPeople((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id_people === id ? { ...pers, [field]: value } : pers,
          ),
        );
      }
    }
  };

  const addNewBuyer = () => {
    const newBuyer = {
      id: null,
      name: null,
      shares: 0,
    };
    setBuyerList([...buyerList, newBuyer]);
  };

  const handleSelectBuyer = (eventKey, e, index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr[index].id = eventKey;
    Arr[index].name = e.target.textContent;
    setBuyerList(Arr);
  };

  const handleChangeBuyer = (shares, index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr[index].shares = shares;
    setBuyerList(Arr);
  };

  const handleDeleteBuyer = (index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr.splice(index, 1);
    setBuyerList(Arr);
  };

  const handleChangeTransaction = (e) => {
    const { name, value } = e.target;
    setTransactionUpdate((prev) => ({ ...prev, [name]: value }));
  };

  const handleMoneyChange = (e) => {
    const newCommission = e.target.value.replace(/[^0-9]/g, '');
    setCommissionMoney(newCommission);
    const newRate = (newCommission / transactionUpdate.price_offer) * 100;
    setCommissionPercentage(newRate.toFixed(4));
    setTransactionUpdate((prev) => ({ ...prev, commission: newCommission }));
  };

  const handlePercentageChange = (e) => {
    const newRate = e.target.value;
    setCommissionPercentage(newRate);
    const newCommission = (newRate / 100) * transactionUpdate.price_offer;
    setCommissionMoney(newCommission.toFixed(0));
    setTransactionUpdate((prev) => ({ ...prev, commission: newCommission }));
  };

  const [isDisplay, setIsDisplay] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
  });
  const handleHeader = (id) => {
    setIsDisplay({ ...isDisplay, [`${id}`]: !isDisplay[`${id}`] });
  };

  const [show, setShow] = useState(false);
  const [bgToast, setBGToast] = useState('danger');
  const [textToast, setTextToast] = useState('Message');
  // Valid button to save data in db
  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (JSON.stringify(boat) !== JSON.stringify(boatUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.DETAILS, boatUpdate, id);
        if (boat.price !== boatUpdate.price) {
          const data = {
            id_events: 3,
            date: new Date(),
            description: `Price update from ${boat.price} to ${boatUpdate.price}`,
          };
          await postDataID(API_ROUTES.BOATS.POST.EVENTS, data, id);
        }
      }
      if (!compareArrays(enginesDefault, enginesUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.ENGINE, enginesUpdate, id);
      }
      if (addEngine.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.ENGINE,
          addEngine,
          id,
        );
        response.status === 200 && setAddEngine([]);
      }
      if (deleteEngines.length > 0) {
        const params = new URLSearchParams();
        deleteEngines.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.ENGINE,
          id,
          params,
        );
        response.status === 200 && setDeleteEngines([]);
      }
      if (
        JSON.stringify(maneuverOptions) !==
        JSON.stringify(maneuverOptionsUpdate)
      ) {
        if (boatUpdate.id_maneuver_options) {
          await putDataID(
            API_ROUTES.BOATS.PUT.MANEUVER_OPTIONS,
            maneuverOptionsUpdate,
            id,
          );
        } else {
          await postDataID(
            API_ROUTES.BOATS.POST.MANEUVER_OPTIONS,
            maneuverOptionsUpdate,
            id,
          );
        }
      }
      if (!compareArrays(batteries, batteriesUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.BATTERIES, batteriesUpdate, id);
      }
      if (addBattery.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.BATTERIES,
          addBattery,
          id,
        );
        response.status === 200 && setAddBattery([]);
      }
      if (deleteBatteries.length > 0) {
        const params = new URLSearchParams();
        deleteBatteries.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.BATTERIES,
          id,
          params,
        );
        response.status === 200 && setDeleteBatteries([]);
      }
      if (!compareArrays(generators, generatorsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.GENERATORS, generatorsUpdate, id);
      }
      if (addGenerator.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.GENERATORS,
          addGenerator,
          id,
        );
        response.status === 200 && setAddGenerator([]);
      }
      if (deleteGenerators.length > 0) {
        const params = new URLSearchParams();
        deleteGenerators.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.GENERATORS,
          id,
          params,
        );
        response.status === 200 && setDeleteGenerators([]);
      }
      if (JSON.stringify(solarPanel) !== JSON.stringify(solarPanelUpdate)) {
        solarPanelUpdate.id
          ? await putDataID(
              API_ROUTES.BOATS.PUT.SOLAR_PANELS,
              solarPanelUpdate,
              id,
            )
          : await postDataID(
              API_ROUTES.BOATS.POST.SOLAR_PANELS,
              solarPanelUpdate,
              id,
            );
      }
      if (JSON.stringify(windturbine) !== JSON.stringify(windturbineUpdate)) {
        windturbineUpdate.id
          ? await putDataID(
              API_ROUTES.BOATS.PUT.WIND_TURBINES,
              windturbineUpdate,
              id,
            )
          : await postDataID(
              API_ROUTES.BOATS.POST.WIND_TURBINES,
              windturbineUpdate,
              id,
            );
      }
      if (JSON.stringify(electronic) !== JSON.stringify(electronicUpdate)) {
        if (boatUpdate.id_electronic) {
          await putDataID(
            API_ROUTES.BOATS.PUT.ELECTRONICS,
            electronicUpdate,
            id,
          );
        } else {
          await postDataID(
            API_ROUTES.BOATS.POST.ELECTRONICS,
            electronicUpdate,
            id,
          );
        }
      }
      if (!compareArrays(tanksDefault, tanksUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.TANKS, tanksUpdate, id);
      }
      if (addTanks.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.TANKS,
          addTanks,
          id,
        );
        response.status === 200 && setAddTanks([]);
      }
      if (deleteTanks.length > 0) {
        const params = new URLSearchParams();
        deleteTanks.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.TANKS,
          id,
          params,
        );
        response.status === 200 && setDeleteTanks([]);
      }
      if (JSON.stringify(comfort) !== JSON.stringify(comfortUpdate)) {
        if (boatUpdate.id_comfort) {
          await putDataID(API_ROUTES.BOATS.PUT.COMFORT, comfortUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.COMFORT, comfortUpdate, id);
        }
      }
      if (!compareArrays(cabinsDefault, cabinsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.CABINS, cabinsUpdate, id);
      }
      if (addCabins.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.CABINS,
          addCabins,
          id,
        );
        response.status === 200 && setAddCabins([]);
      }
      if (deleteCabins.length > 0) {
        const params = new URLSearchParams();
        deleteCabins.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.CABINS,
          id,
          params,
        );
        response.status === 200 && setDeleteCabins([]);
      }
      if (!compareArrays(kitchensDefault, kitchensUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.KITCHENS, kitchensUpdate, id);
      }
      if (addKitchens.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.KITCHENS,
          addKitchens,
          id,
        );
        response.status === 200 && setAddKitchens([]);
      }
      if (deleteKitchens.length > 0) {
        const params = new URLSearchParams();
        deleteKitchens.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.KITCHENS,
          id,
          params,
        );
        response.status === 200 && setDeleteKitchens([]);
      }
      if (!compareArrays(bathroomDefault, bathroomUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.BATHROOMS, bathroomUpdate, id);
      }
      if (addBathrooms.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.BATHROOMS,
          addBathrooms,
          id,
        );
        response.status === 200 && setAddBathrooms([]);
      }
      if (deleteBathrooms.length > 0) {
        const params = new URLSearchParams();
        deleteBathrooms.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.BATHROOMS,
          id,
          params,
        );
        response.status === 200 && setDeleteBathrooms([]);
      }
      if (JSON.stringify(cover) !== JSON.stringify(coverUpdate)) {
        if (boatUpdate.id_cover) {
          await putDataID(API_ROUTES.BOATS.PUT.COVER, coverUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.COVER, coverUpdate, id);
        }
      }
      if (!compareArrays(sailsDefault, sailsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.SAILS, sailsUpdate, id);
      }
      if (addSails.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.SAILS,
          addSails,
          id,
        );
        response.status === 200 && setAddSails([]);
      }
      if (deleteSails.length > 0) {
        const params = new URLSearchParams();
        deleteSails.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.SAILS,
          id,
          params,
        );
        response.status === 200 && setDeleteSails([]);
      }
      if (JSON.stringify(rigging) !== JSON.stringify(riggingUpdate)) {
        if (boatUpdate.id_rigging) {
          await putDataID(API_ROUTES.BOATS.PUT.RIGGINGS, riggingUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.RIGGINGS, riggingUpdate, id);
        }
      }
      if (JSON.stringify(fittings) !== JSON.stringify(fittingsUpdate)) {
        if (boatUpdate.id_fittings) {
          await putDataID(API_ROUTES.BOATS.PUT.FITTINGS, fittingsUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.FITTINGS, fittingsUpdate, id);
        }
      }
      if (JSON.stringify(securityDefault) !== JSON.stringify(securityUpdate)) {
        if (boatUpdate.id_security) {
          await putDataID(API_ROUTES.BOATS.PUT.SECURITY, securityUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.SECURITY, securityUpdate, id);
        }
      }
      if (!compareArrays(securityCertifDefault, securityCertifUpdate)) {
        await putDataID(
          API_ROUTES.BOATS.PUT.SECURITY_CATEGORY,
          securityCertifUpdate,
          id,
        );
      }
      if (addCertif.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.SECURITY_CATEGORY,
          addCertif,
          id,
        );
        response.status === 200 && setAddCertif([]);
      }
      if (delCertif.length > 0) {
        const params = new URLSearchParams();
        delCertif.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.SECURITY_CATEGORY,
          id,
          params,
        );
        response.status === 200 && setDelCertif([]);
      }
      if (addingOwner.length > 0) {
        await postDataID(API_ROUTES.BOATS.POST.PEOPLE, addingOwner, id);
      }
      if (addingLead.length > 0) {
        await postDataID(API_ROUTES.BOATS.POST.PEOPLE, addingLead, id);
      }
      if (deletedPeople.length > 0) {
        const params = new URLSearchParams();
        deletedPeople.forEach((item) => params.append('id', item));
        await deleteDataID(API_ROUTES.BOATS.DELETE.PEOPLE, id, params);
      }
      if (!compareArrays(peopleDefault, people)) {
        await putDataID(API_ROUTES.BOATS.PUT.PEOPLE, people, id);
      }
      if (!transaction && transactionUpdate) {
        console.log('create transaction');
        transactionUpdate.id_user = JSON.parse(
          localStorage.getItem('ids'),
        ).user;
        transactionUpdate.buyers = buyerList;
        transactionUpdate.sellers = person
          .map((pers) => {
            const boatInfo = people?.find((pb) => pb.id_people === pers.id);
            return {
              ...pers,
              status: boatInfo.id_status || null,
              shares: boatInfo.shares || null,
              contact: boatInfo.contact || 0,
              commission: boatInfo.commission / 100 || null,
            };
          })
          .filter((x) => x.status === 1);
        await postDataID(
          API_ROUTES.TRANSACTIONS.POST.CREATE,
          transactionUpdate,
          id,
        );
      }
      if (
        transaction &&
        JSON.stringify(transaction) !== JSON.stringify(transactionUpdate)
      ) {
        console.log('update transaction');
        await putDataID(
          API_ROUTES.TRANSACTIONS.PUT.UPDATE,
          transactionUpdate,
          transactionUpdate.id,
        );
      }

      setBGToast('success');
      setTextToast('Successfully saved!');
      setShow(true);
      setSaving(false);
      window.location.reload(); // DELETE THIS => reload component
    } catch (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login'); // redirect to login page if error
      } else {
        console.log('index boat handlesave L1000 error: ', error);
        setBGToast('danger');
        setTextToast('ERROR: Failed saved.');
        setShow(true);
      }
      setSaving(false);
    }
  };
  // cancel button to reset value
  const handleCancel = () => {
    setBoatUpdate(boat);
    setDeleteEngines([]);
    setAddEngine([]);
    setEnginesUpdate(enginesDefault);
    setManeuverOptionsUpdate(maneuverOptions);
    setBatteriesUpdate(batteries);
    setAddBattery([]);
    setDeleteBatteries([]);
    setGeneratorsUpdate(generators);
    setAddGenerator([]);
    setDeleteGenerators([]);
    setSolarPanelUpdate(solarPanel);
    setWindturbineUpdate(windturbine);
    setElectronicUpdate(electronic);
    setTanksUpdate(tanksDefault);
    setAddTanks([]);
    setDeleteTanks([]);
    setComfortUpdate(comfort);
    setCabinsUpdate(cabinsDefault);
    setAddCabins([]);
    setDeleteCabins([]);
    setKitchensUpdate(kitchensDefault);
    setAddKitchens([]);
    setDeleteKitchens([]);
    setBathroomUpdate(bathroomDefault);
    setAddBathrooms([]);
    setDeleteBathrooms([]);
    setCoverUpdate(cover);
    setSailsUpdate(sailsDefault);
    setAddSails([]);
    setDeleteSails([]);
    setRiggingUpdate(rigging);
    setFittingsUpdate(fittings);
    setSecurityUpdate(securityDefault);
    setSecurityCertifUpdate(securityCertifDefault);
    setAddCertif([]);
    setDelCertif([]);
    setAddingOwner([]);
    setAddingLead([]);
    setPerson(personDefault);
    setPeople(peopleDefault);
    setDeletedPeople([]);
    setTransactionUpdate(transaction);
    if (transaction) {
      setCommissionMoney(transaction.commission);
      setCommissionPercentage(
        ((transaction.commission / transaction.price_offer) * 100)
          .toFixed(4)
          .toString()
          .replace(/\.00$/, ''),
      );
    }
    if (!transaction) {
      setBuyerList([]);
    }
  };

  return (
    <div className="boat">
      <section className="boat__carousel">
        <Carousel id={id} user={user} />
      </section>
      <Accordion
        as={Form}
        id="form"
        onSubmit={(e) => handleSave(e)}
        onReset={handleCancel}
        alwaysOpen
        className="boat__accordion"
      >
        {details ? (
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => handleHeader(0)} as="div">
              <section className="accordion__specifications">
                <div className="specifications__info">
                  <span className="info__strong">
                    {details.brand} - {details.model}
                  </span>
                  <span className="info__status">
                    {details.name && `"${details.name}" - `}Status:{' '}
                    {details.status}
                  </span>
                </div>
                <hr />
                {isDisplay['0'] && (
                  <React.Fragment>
                    <Row>
                      <span className="col-4">Year: {details.year}</span>
                      <span className="col-4">
                        Typology: {details.typology}
                      </span>
                      <span className="col-4">Flag: {details.flag}</span>
                    </Row>
                    <Row>
                      <div className="col-4">
                        {details.draft > 0 && (
                          <span className="specifications__measures">
                            Draft:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.draft / 100)}
                          </span>
                        )}
                        {details.air_draft > 0 && (
                          <span className="specifications__measures">
                            Air-draft:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.air_draft / 100)}
                          </span>
                        )}
                      </div>
                      <div className="col-4">
                        {details.length > 0 && (
                          <span className="specifications__measures">
                            Length:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.act_length / 100)}
                          </span>
                        )}
                        {details.act_width > 0 && (
                          <span className="specifications__measures">
                            Width:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.act_width / 100)}
                          </span>
                        )}
                      </div>
                      <div className="col-4">
                        {details.adm_length > 0 && (
                          <span className="specifications__measures">
                            Administrative length:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.adm_length / 100)}
                          </span>
                        )}
                        {details.adm_width > 0 && (
                          <span className="specifications__measures">
                            Administrative width:{' '}
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'unit',
                              unit: 'meter',
                            }).format(details.adm_width / 100)}
                          </span>
                        )}
                      </div>
                    </Row>
                    <Row>
                      <span className="col-4">
                        {details.headroom &&
                          `Headroom: ${new Intl.NumberFormat('fr-FR', {
                            style: 'unit',
                            unit: 'meter',
                          }).format(details.headroom / 100)}`}
                      </span>
                      <span className="col-4">
                        {details.weight &&
                          `Weight: ${new Intl.NumberFormat('fr-FR', {
                            style: 'unit',
                            unit: 'kilogram',
                          }).format(details.weight)}`}
                      </span>
                      <span className="col-4">
                        {details &&
                          enginesDisplay &&
                          `Tax nav: ${new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 0,
                          }).format(
                            dafn(
                              details.typology,
                              details.year,
                              enginesDisplay,
                              details.adm_length / 100,
                            ),
                          )}`}
                      </span>
                    </Row>
                    <Row>
                      <span className="col-4">
                        {details.serial_number &&
                          `Serial Number: ${details.serial_number}`}
                      </span>
                      <span className="col-4"></span>
                      <span className="col-4">
                        {details.registration &&
                          `Registration: ${details.registration}`}
                      </span>
                    </Row>
                    <hr />
                    <div className="specifications__info">
                      <span className="info__strong">
                        Price:{' '}
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 0,
                        }).format(details.price)}
                      </span>
                    </div>
                  </React.Fragment>
                )}
              </section>
            </Accordion.Header>
            {user && boat && models && brands && (
              <Accordion.Body className="accordion__update">
                <Row className="mb-4">
                  <Form.Group as={Col} md={2}>
                    {brands.length > 0 && models.length > 0 ? (
                      <Form.Control
                        placeholder="Brand"
                        defaultValue={
                          brands?.find(
                            (x) =>
                              x.id ===
                              models?.find((y) => y.id === boat?.id_model)
                                ?.id_brand,
                          )?.value
                        }
                        onChange={(e) =>
                          setBoatUpdate({
                            ...boatUpdate,
                            brand: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <span>Loading</span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col}>
                    {boat && models.length > 0 ? (
                      <Form.Control
                        placeholder="Model"
                        defaultValue={
                          models?.find((y) => y.id === boat?.id_model)?.value
                        }
                        onChange={(e) =>
                          setBoatUpdate({
                            ...boatUpdate,
                            model: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <span>Loading</span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Control
                      placeholder="Name"
                      defaultValue={boat.name}
                      onChange={(e) => {
                        setBoatUpdate({ ...boatUpdate, name: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Select
                      value={boatUpdate.id_status}
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          id_status: e.target.value,
                        });
                      }}
                    >
                      {status.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.value}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <hr />
                <Row className="mb-3">
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={2}>
                      Year
                    </Form.Label>
                    <Form.Control
                      placeholder="Year"
                      value={boatUpdate.year}
                      maxLength={4}
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          year: e.target.value.replace(/[^0-9]/g, ''),
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Select
                      value={boatUpdate.id_typology}
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          id_typology: e.target.value,
                        });
                      }}
                    >
                      {typologies.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.value}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Select
                      value={boatUpdate.id_flag}
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          id_flag: e.target.value,
                        });
                      }}
                    >
                      {flags.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.value}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={2}>
                      Draft
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Draft"
                        value={boatUpdate.draft / 100}
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            draft: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={2}>
                      Length
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Length"
                        defaultValue={
                          details.act_length / 100 !== 0
                            ? details.act_length / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            act_length: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={5}>
                      Admin. Length
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Admin. Length"
                        defaultValue={
                          details.adm_length / 100 !== 0
                            ? details.adm_length / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            adm_length: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={3}>
                      Air draft
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Air draft"
                        defaultValue={
                          details.air_draft / 100 !== 0
                            ? details.air_draft / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            air_draft: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={2}>
                      Width
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Width"
                        defaultValue={
                          details.act_width / 100 !== 0
                            ? details.act_width / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            act_width: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={5}>
                      Admin. Width
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Admin. Width"
                        defaultValue={
                          details.adm_width / 100 !== 0
                            ? details.adm_width / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            adm_width: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={3}>
                      Headroom
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Headroom"
                        defaultValue={
                          details.headroom / 100 !== 0
                            ? details.headroom / 100
                            : null
                        }
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            headroom: e.target.value * 100,
                          });
                        }}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={2}>
                      Weight
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Weight"
                        defaultValue={details.weight}
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            weight: e.target.value,
                          });
                        }}
                      />
                      <InputGroup.Text>kg</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={5}>
                      Tax nav
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Tax nav"
                        defaultValue={
                          details && enginesDisplay
                            ? dafn(
                                details.typology,
                                details.year,
                                enginesDisplay,
                                details.adm_length / 100,
                              )
                            : 0
                        }
                        readOnly
                      />
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={4}>
                      Serial number
                    </Form.Label>
                    <Form.Control
                      placeholder="Serial number"
                      defaultValue={
                        details.serial_number !== 0
                          ? details.serial_number
                          : null
                      }
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          serial_number: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="update__label"></Form.Group>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label column md={4}>
                      Registration
                    </Form.Label>
                    <Form.Control
                      placeholder="Registration"
                      defaultValue={
                        details.registration !== 0 ? details.registration : null
                      }
                      onChange={(e) => {
                        setBoatUpdate({
                          ...boatUpdate,
                          registration: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Row>
                <hr />
                <Row>
                  <Form.Group as={Col} className="update__label">
                    <Form.Label>Price</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Price"
                        defaultValue={details.price}
                        onChange={(e) => {
                          setBoatUpdate({
                            ...boatUpdate,
                            price: e.target.value,
                          });
                        }}
                      />
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Accordion.Body>
            )}
          </Accordion.Item>
        ) : (
          <span>Loading...</span>
        )}
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={() => handleHeader(1)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Engines</span>
              <hr />
              {isDisplay['1'] && (
                <React.Fragment>
                  <div className="header__container">
                    {enginesDisplay &&
                      enginesDisplay.map((element, index) => {
                        return (
                          <Row key={index}>
                            <span className="accordion__subtitle">
                              Engine {index + 1}
                            </span>
                            <Row>
                              <span className="col-6">
                                Brand: {element.brand}
                              </span>
                              <span className="col-6">
                                {element.model && `Model: ${element.model}`}
                              </span>
                            </Row>
                            <Row>
                              <span className="col-6">
                                Power: {element.power} CV
                              </span>
                              <span className="col-6">
                                Fiscal Power: {element.fiscal_power} CV
                              </span>
                            </Row>
                            <Row>
                              <span className="col-6">
                                {element.hours > 0 &&
                                  `Hours: ${new Intl.NumberFormat('fr-FR', {
                                    style: 'unit',
                                    unit: 'hour',
                                  }).format(element.hours)}`}
                              </span>
                              {element.transmission !== 'None' && (
                                <span className="col-6">
                                  Transmission: {element.transmission}
                                </span>
                              )}
                            </Row>
                            <Row>
                              <span>
                                {element.serial_number &&
                                  `Serial number ${element.serial_number}`}
                              </span>
                            </Row>
                          </Row>
                        );
                      })}
                  </div>
                  {maneuverOptions && (
                    <Row>
                      <span className="accordion__subtitle">
                        Maneuver Options
                      </span>
                      <Row>
                        <span className="col-4">
                          Bow Thruster:{' '}
                          {maneuverOptions?.bow_thruster ? 'Yes' : 'No'}
                        </span>
                        {maneuverOptions?.bow_thruster && (
                          <p className="col-8">
                            {maneuverOptions?.bow_thruster_description}
                          </p>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          Stern Thruster:{' '}
                          {maneuverOptions?.stern_thruster ? 'Yes' : 'No'}
                        </span>
                        {maneuverOptions?.stern_thruster && (
                          <p className="col-8">
                            {maneuverOptions?.stern_thruster_description}
                          </p>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          Flaps: {maneuverOptions?.flaps ? 'Yes' : 'No'}
                        </span>
                        {maneuverOptions?.flaps && (
                          <p className="col-8">
                            {maneuverOptions?.flaps_description}
                          </p>
                        )}
                      </Row>
                      <Row>
                        <span className="col-6">
                          Mooring Winch:{' '}
                          {maneuverOptions?.mooring_winch ? 'Yes' : 'No'}
                        </span>
                        <span className="col-6">
                          Yacht Controller:{' '}
                          {maneuverOptions?.yacht_controller ? 'Yes' : 'No'}
                        </span>
                      </Row>
                    </Row>
                  )}
                </React.Fragment>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              <Row>
                {enginesUpdate &&
                  enginesUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Engine {index + 1}
                          </span>
                          <div className="btn__container">
                            <Button
                              variant="warning"
                              onClick={() => duplicateEngine(element.id)}
                              className="canDelete__button"
                            >
                              <IoDuplicate />
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => deleteEngine(element.id)}
                              className="canDelete__button"
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </div>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Brand
                            </Form.Label>
                            <Form.Control
                              placeholder="Brand"
                              defaultValue={element.brand}
                              onChange={(e) =>
                                updateEnginesField(e, index, 'brand')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Model
                            </Form.Label>
                            <Form.Control
                              placeholder="Model"
                              defaultValue={element.model}
                              onChange={(e) =>
                                updateEnginesField(e, index, 'model')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Power
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Power"
                                defaultValue={element.power}
                                onChange={(e) =>
                                  updateEnginesField(e, index, 'power')
                                }
                              />
                              <InputGroup.Text>CV</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Fiscal Power
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Fiscal Power"
                                defaultValue={element.fiscal_power}
                                onChange={(e) =>
                                  updateEnginesField(e, index, 'fiscal_power')
                                }
                              />
                              <InputGroup.Text>CV</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Hours
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Hours"
                                defaultValue={element.hours}
                                onChange={(e) =>
                                  updateEnginesField(e, index, 'hours')
                                }
                              />
                              <InputGroup.Text>h</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Transmission
                            </Form.Label>
                            <Form.Select
                              defaultValue={element.id_transmission}
                              onChange={(e) =>
                                updateEnginesField(e, index, 'id_transmission')
                              }
                            >
                              {transmission.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={2}>
                              Serial number
                            </Form.Label>
                            <Form.Control
                              placeholder="Serial number"
                              defaultValue={element.serial_number}
                              onChange={(e) =>
                                updateEnginesField(e, index, 'serial_number')
                              }
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addEngine.map((element, index) => {
                    return (
                      <AddEngine
                        key={index}
                        transmission={transmission}
                        engine={element}
                        deleteItem={deleteObjectEngine}
                        duplicateItem={duplicateNewEngine}
                      />
                    );
                  })}
                  <Button onClick={addNewEngine} className="update__addButton">
                    <IoMdAddCircleOutline />
                    <span>Add new Engine</span>
                  </Button>
                </Row>
                <Row>
                  <span className="accordion__subtitle">Maneuver Options</span>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Bow Thruster"
                      checked={maneuverOptionsUpdate?.bow_thruster || false}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          bow_thruster: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Control
                      as="textarea"
                      rows={1}
                      disabled={!maneuverOptionsUpdate?.bow_thruster}
                      value={maneuverOptions?.bow_thruster_description || ''}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          bow_thruster_description: e.target.value,
                        })
                      }
                      className="container__description"
                    />
                  </Form.Group>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Stern Thruster"
                      checked={maneuverOptionsUpdate?.stern_thruster || false}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          stern_thruster: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Control
                      as="textarea"
                      rows={1}
                      disabled={!maneuverOptionsUpdate?.stern_thruster}
                      defaultValue={maneuverOptions?.stern_thruster_description}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          stern_thruster_description: e.target.value,
                        })
                      }
                      className="container__description"
                    />
                  </Form.Group>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Flaps"
                      checked={maneuverOptionsUpdate?.flaps || false}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          flaps: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Control
                      as="textarea"
                      rows={1}
                      disabled={!maneuverOptionsUpdate?.flaps}
                      defaultValue={maneuverOptions?.flaps_description}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          flaps_description: e.target.value,
                        })
                      }
                      className="container__description"
                    />
                  </Form.Group>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Mooring Winch"
                      checked={maneuverOptionsUpdate?.mooring_winch || false}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          mooring_winch: e.target.checked,
                        })
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      label="Yacht Controller"
                      checked={maneuverOptionsUpdate?.yacht_controller || false}
                      onChange={(e) =>
                        setManeuverOptionsUpdate({
                          ...maneuverOptionsUpdate,
                          yacht_controller: e.target.checked,
                        })
                      }
                    />
                  </Form.Group>
                </Row>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header onClick={() => handleHeader(2)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Electricy</span>
              <hr />
              {isDisplay['2'] && (
                <React.Fragment>
                  {batteries && (
                    <React.Fragment>
                      <span className="row accordion__subtitle">
                        Battery Park
                      </span>
                      <div className="header__container">
                        {batteries.map((element, index) => {
                          return (
                            <Row key={index}>
                              <Row>
                                <span className="col-4">
                                  Quantity: {element.quantity}
                                </span>
                                <span className="col-4">
                                  Assignment: {element.assignment}
                                </span>
                                {element.type && (
                                  <span className="col-4">
                                    Type: {element.type}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  Voltage: {element.voltage} V
                                </span>
                                {element.amperage && (
                                  <span className="col-4">
                                    Amperage: {element.amperage} A
                                  </span>
                                )}
                              </Row>
                            </Row>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                  {generators && (
                    <React.Fragment>
                      <span className="row accordion__subtitle">
                        Generators
                      </span>
                      <div className="header__container">
                        {generators.map((element, index) => {
                          return (
                            <Row key={index}>
                              <Row>
                                <span className="col-6">
                                  Brand: {element.brand}
                                </span>
                                {element.model && (
                                  <span className="col-6">
                                    Model: {element.model}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-6">
                                  Power: {element.power} kW
                                </span>
                                {element.hours > 0 && (
                                  <span className="col-6">
                                    Hours:{' '}
                                    {new Intl.NumberFormat('fr-FR', {
                                      style: 'unit',
                                      unit: 'hour',
                                    }).format(element.hours)}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                {element.serial_number && (
                                  <span>
                                    Serial number: {element.serial_number}
                                  </span>
                                )}
                              </Row>
                            </Row>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                  {solarPanel && (
                    <div className="header__container">
                      <span className="row accordion__subtitle">
                        Solar Panel
                      </span>
                      <Row>
                        <span className="col-4">Power: {solarPanel.power}</span>
                        {solarPanel.description && (
                          <span className="col-8">
                            {solarPanel.description}
                          </span>
                        )}
                      </Row>
                    </div>
                  )}
                  {windturbine && (
                    <div className="header__container">
                      <span className="row accordion__subtitle">
                        Windturbine
                      </span>
                      <Row>
                        <span className="col-4">
                          Quantity: {windturbine.quantity}
                        </span>
                        {windturbine.description && (
                          <span className="col-8">
                            {windturbine.description}
                          </span>
                        )}
                      </Row>
                    </div>
                  )}
                  <div className="header__container">
                    {electronic && (
                      <Row>
                        <span className="row accordion__subtitle">
                          Electronic
                        </span>
                        <Row>
                          <Row>
                            <span className="col-4">
                              Loch & Speedo:{' '}
                              {electronic.loch_speedo ? 'Yes' : 'No'}
                            </span>
                            {electronic.loch_speedo && (
                              <span className="col-8">
                                {electronic.loch_speedo_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              Sounder: {electronic.sounder ? 'Yes' : 'No'}
                            </span>
                            {electronic.sounder && (
                              <span className="col-8">
                                {electronic.sounder_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              GPS: {electronic.gps ? 'Yes' : 'No'}
                            </span>
                            {electronic.gps && (
                              <span className="col-8">
                                {electronic.gps_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              VHF: {electronic.vhf ? 'Yes' : 'No'}
                            </span>
                            {electronic.vhf && (
                              <span className="col-8">
                                {electronic.vhf_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              Radar: {electronic.radar ? 'Yes' : 'No'}
                            </span>
                            {electronic.radar && (
                              <span className="col-8">
                                {electronic.radar_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              AIS: {electronic.ais ? 'Yes' : 'No'}
                            </span>
                            {electronic.ais && (
                              <span className="col-8">
                                {electronic.ais_description}
                              </span>
                            )}
                          </Row>
                        </Row>
                      </Row>
                    )}
                  </div>
                </React.Fragment>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              <Row>
                {batteriesUpdate &&
                  batteriesUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Battery Park {index + 1}
                          </span>
                          <Button
                            variant="danger"
                            onClick={() => deleteBattery(element.id)}
                            className="canDelete__button"
                          >
                            <FaRegTrashAlt />
                          </Button>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Quantity
                            </Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              defaultValue={element.quantity}
                              onChange={(e) =>
                                updateBatteryField(e, index, 'quantity')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Assignment
                            </Form.Label>
                            <Form.Control
                              placeholder="Assignment"
                              defaultValue={element.assignment}
                              onChange={(e) =>
                                updateBatteryField(e, index, 'assignment')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={2}>
                              Type
                            </Form.Label>
                            <Form.Control
                              placeholder="Type"
                              defaultValue={element.type}
                              onChange={(e) =>
                                updateBatteryField(e, index, 'type')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Voltage
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Voltage"
                                defaultValue={element.voltage}
                                onChange={(e) =>
                                  updateBatteryField(e, index, 'voltage')
                                }
                              />
                              <InputGroup.Text>V</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Amperage
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Amperage"
                                defaultValue={element.amperage}
                                onChange={(e) =>
                                  updateBatteryField(e, index, 'amperage')
                                }
                              />
                              <InputGroup.Text>A</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="update__label"
                          ></Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addBattery.map((element, index) => {
                    return (
                      <AddBattery
                        key={index}
                        battery={element}
                        deleteItem={deleteObjectBattery}
                      />
                    );
                  })}
                  <Button onClick={addNewBattery} className="update__addButton">
                    <IoMdAddCircleOutline />
                    <span>Add new Battery Park</span>
                  </Button>
                </Row>
                {generatorsUpdate &&
                  generatorsUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Generator {index + 1}
                          </span>
                          <Button
                            variant="danger"
                            onClick={() => deleteGenerator(element.id)}
                            className="canDelete__button"
                          >
                            <FaRegTrashAlt />
                          </Button>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Brand
                            </Form.Label>
                            <Form.Control
                              placeholder="Brand"
                              defaultValue={element.brand}
                              onChange={(e) =>
                                updateGeneratorField(e, index, 'brand')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Model
                            </Form.Label>
                            <Form.Control
                              placeholder="Model"
                              defaultValue={element.model}
                              onChange={(e) =>
                                updateGeneratorField(e, index, 'model')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={3}>
                              Power
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Power"
                                defaultValue={element.power}
                                onChange={(e) =>
                                  updateGeneratorField(e, index, 'power')
                                }
                              />
                              <InputGroup.Text>kW</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={4}>
                              Hours
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                placeholder="Hours"
                                defaultValue={element.hours}
                                onChange={(e) =>
                                  updateGeneratorField(e, index, 'hours')
                                }
                              />
                              <InputGroup.Text>h</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={2}>
                              Serial number
                            </Form.Label>
                            <Form.Control
                              placeholder="Serial number"
                              defaultValue={element.serial_number}
                              onChange={(e) =>
                                updateGeneratorField(e, index, 'serial_number')
                              }
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addGenerator.map((element, index) => {
                    return (
                      <AddGenerator
                        key={index}
                        generator={element}
                        deleteItem={deleteObjectGenerator}
                      />
                    );
                  })}
                  <Button
                    onClick={addNewGenerator}
                    className="update__addButton"
                  >
                    <IoMdAddCircleOutline />
                    <span>Add new Generator</span>
                  </Button>
                </Row>
                <Row>
                  <span className="row accordion__subtitle">Solar Panel</span>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={3} className="update__label">
                      <Form.Label column md={4}>
                        Power
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Power"
                          defaultValue={solarPanel && solarPanel.power}
                          onChange={(e) =>
                            setSolarPanelUpdate({
                              ...solarPanelUpdate,
                              power: e.target.value,
                            })
                          }
                        />
                        <InputGroup.Text>W</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} className="update__label">
                      <Form.Control
                        placeholder="Description"
                        defaultValue={solarPanel && solarPanel.description}
                        onChange={(e) =>
                          setSolarPanelUpdate({
                            ...solarPanelUpdate,
                            description: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Row>
                <Row>
                  <span className="row accordion__subtitle">Windturbine</span>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={3} className="update__label">
                      <Form.Label column md={4}>
                        Quantity
                      </Form.Label>
                      <Form.Control
                        placeholder="Quantity"
                        defaultValue={windturbine && windturbine.quantity}
                        onChange={(e) =>
                          setWindturbineUpdate({
                            ...windturbineUpdate,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="update__label">
                      <Form.Control
                        placeholder="Description"
                        defaultValue={windturbine && windturbine.description}
                        onChange={(e) =>
                          setWindturbineUpdate({
                            ...windturbineUpdate,
                            description: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Row>
                <Row>
                  <span className="row accordion__subtitle">Electronic</span>
                  <Row className="mb-3">
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Loch & Speedo"
                        checked={electronicUpdate?.loch_speedo || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            loch_speedo: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.loch_speedo}
                        defaultValue={electronicUpdate?.loch_speedo_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            loch_speedo_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Sounder"
                        checked={electronicUpdate?.sounder || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            sounder: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.sounder}
                        defaultValue={electronicUpdate?.sounder_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            sounder_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="GPS"
                        checked={electronicUpdate?.gps || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            gps: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.gps}
                        defaultValue={electronicUpdate?.gps_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            gps_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="VHF"
                        checked={electronicUpdate?.vhf || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            vhf: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.vhf}
                        defaultValue={electronicUpdate?.vhf_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            vhf_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Radar"
                        checked={electronicUpdate?.radar || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            radar: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.radar}
                        defaultValue={electronicUpdate?.radar_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            radar_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label="AIS"
                        checked={electronicUpdate?.ais || false}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            ais: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!electronicUpdate?.ais}
                        defaultValue={electronicUpdate?.ais_description}
                        onChange={(e) =>
                          setElectronicUpdate({
                            ...electronicUpdate,
                            ais_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                  </Row>
                </Row>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header onClick={() => handleHeader(3)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Tanks</span>
              <hr />
              {isDisplay['3'] && (
                <Row>
                  {tanks &&
                    tanks.map((element, index) => {
                      return (
                        <div key={index} className="col-3">
                          <span className="row accordion__subtitle">
                            Tank {index + 1}
                          </span>
                          <Row>
                            <span>Capacity: {element.capacity}</span>
                            <span>Type: {element.type}</span>
                            {element.material && (
                              <span>Material: {element.material}</span>
                            )}
                          </Row>
                        </div>
                      );
                    })}
                </Row>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              {tanksUpdate &&
                tanksUpdate.map((element, index) => {
                  return (
                    <Row key={index}>
                      <Col className="update__canDelete">
                        <span className="accordion__subtitle">
                          Tank {index + 1}
                        </span>
                        <Button
                          variant="danger"
                          onClick={() => deleteTank(element.id)}
                          className="canDelete__button"
                        >
                          <FaRegTrashAlt />
                        </Button>
                      </Col>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="update__label">
                          <Form.Label column md={3}>
                            Capacity
                          </Form.Label>
                          <Form.Control
                            placeholder="Capacity"
                            defaultValue={element.capacity}
                            onChange={(e) =>
                              updateTanksField(e, index, 'capacity')
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="update__label">
                          <Form.Label column md={3}>
                            Type
                          </Form.Label>
                          <Form.Select
                            value={
                              element.id_tanks_type === null
                                ? 0
                                : element.id_tanks_type
                            }
                            onChange={(e) =>
                              updateTanksField(e, index, 'id_type')
                            }
                          >
                            {tanksTypes.map((element, index) => {
                              return (
                                <option key={index} value={element.id}>
                                  {element.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} className="update__label">
                          <Form.Label column md={3}>
                            Material
                          </Form.Label>
                          <Form.Select
                            value={
                              element.id_material === null
                                ? 0
                                : element.id_material
                            }
                            onChange={(e) =>
                              updateTanksField(e, index, 'id_material')
                            }
                          >
                            <option value={0}>None</option>
                            {material.map((element, index) => {
                              return (
                                <option key={index} value={element.id}>
                                  {element.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Row>
                    </Row>
                  );
                })}
              <Row>
                {addTanks.map((element, index) => {
                  return (
                    <AddTank
                      key={index}
                      tanksTypes={tanksTypes}
                      material={material}
                      tank={element}
                      deleteItem={deleteObjectTank}
                    />
                  );
                })}
                <Button onClick={addNewTank} className="update__addButton">
                  <IoMdAddCircleOutline />
                  <span>Add new Tank</span>
                </Button>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header onClick={() => handleHeader(4)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Equipments</span>
              <hr />
              {isDisplay['4'] && (
                <React.Fragment>
                  {comfort && (
                    <Row>
                      <span className="accordion__subtitle">Comfort</span>
                      <div className="header__container">
                        <Row>
                          <Row>
                            <span className="col-4">
                              Air conditioning:{' '}
                              {comfort.air_conditioning ? 'Yes' : 'No'}
                            </span>
                            {comfort.air_conditioning && (
                              <span className="col-8">
                                {comfort.air_conditioning_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              Pulse air heating:{' '}
                              {comfort.pulse_air_heating ? 'Yes' : 'No'}
                            </span>
                            {comfort.pulse_air_heating && (
                              <span className="col-8">
                                {comfort.pulse_air_heating_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            {comfort.hot_water_capacity > 0 && (
                              <span className="col-4">
                                Hot water capacity: {comfort.hot_water_capacity}{' '}
                                L
                              </span>
                            )}
                            {comfort.hot_water_capacity > 0 && (
                              <span className="col-8">
                                {comfort.hot_water_capacity_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            {comfort.deck_shower ? (
                              <span className="col-3">Deck shower: Yes</span>
                            ) : (
                              <span className="col-3">Deck shower: No</span>
                            )}
                            {comfort.pressurized_water ? (
                              <span className="col-3">
                                Pressurized Water: Yes
                              </span>
                            ) : (
                              <span className="col-3">
                                Pressurized Water: No
                              </span>
                            )}
                            {comfort.water_maker ? (
                              <span className="col-3">Water maker: Yes</span>
                            ) : (
                              <span className="col-3">Water maker: No</span>
                            )}
                            {comfort.ice_maker ? (
                              <span className="col-3">Ice maker: Yes</span>
                            ) : (
                              <span className="col-3">Ice maker: No</span>
                            )}
                          </Row>
                        </Row>
                      </div>
                    </Row>
                  )}
                  {cabinsDisplay && (
                    <React.Fragment>
                      <span className="accordion__subtitle">Cabins</span>
                      <div className="header__container">
                        {cabinsDisplay.map((element, index) => {
                          return (
                            <Row key={index}>
                              <span className="accordion__subtitle">
                                Cabin {index + 1}
                              </span>
                              <Row>
                                <span className="col-4">
                                  Type: {element.type}
                                </span>
                                <span className="col-4">
                                  Position: {element.position}
                                </span>
                              </Row>
                              <Row>
                                <span className="col-4">
                                  Quantity single bed:{' '}
                                  {element.quantity_single_bed}
                                </span>
                                <span className="col-4">
                                  Air conditioning:{' '}
                                  {element.air_conditioning ? 'Yes' : 'No'}
                                </span>
                                <span className="col-4">
                                  Pulse air heating:{' '}
                                  {element.pulse_air_heating ? 'Yes' : 'No'}
                                </span>
                              </Row>
                              <Row>
                                <span className="col-4">
                                  Double bed:{' '}
                                  {element.double_bed ? 'Yes' : 'No'}
                                </span>
                                {element.double_bed && (
                                  <span className="col-8">
                                    {element.double_bed_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  Hifi: {element.hifi ? 'Yes' : 'No'}
                                </span>
                                {element.hifi && (
                                  <span className="col-8">
                                    {element.hifi_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  TV: {element.tv ? 'Yes' : 'No'}
                                </span>
                                {element.tv && (
                                  <span className="col-8">
                                    {element.tv_description}
                                  </span>
                                )}
                              </Row>
                            </Row>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                  <Row>
                    {kitchens && (
                      <React.Fragment>
                        <span className="accordion__subtitle">Kitchens</span>
                        <div className="header__container">
                          {kitchens.map((element, index) => {
                            return (
                              <Row key={index}>
                                <Row>
                                  {element.hotplates && (
                                    <span className="col-4">
                                      Hotplates: {element.hotplates}
                                    </span>
                                  )}
                                  {element.hotplates_description && (
                                    <span className="col-8">
                                      {element.hotplates_description}
                                    </span>
                                  )}
                                </Row>
                                <Row>
                                  <span className="col-4">
                                    Quantity oven: {element.quantity_oven}
                                  </span>
                                  {element.oven && (
                                    <span className="col-8">
                                      {element.oven}
                                    </span>
                                  )}
                                </Row>
                                <Row>
                                  <span className="col-4">
                                    Microwave:{' '}
                                    {element.microwave ? 'Yes' : 'No'}
                                  </span>
                                  {element.quantity_fridge && (
                                    <span className="col-4">
                                      Quantity fridge: {element.quantity_fridge}
                                    </span>
                                  )}
                                  {element.quantity_freezer && (
                                    <span className="col-4">
                                      Quantity freezer:{' '}
                                      {element.quantity_freezer}
                                    </span>
                                  )}
                                </Row>
                                {element.description && (
                                  <span>{element.description}</span>
                                )}
                              </Row>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </Row>
                  <Row>
                    {bathroom && (
                      <React.Fragment>
                        <span className="accordion__subtitle">Bathrooms</span>
                        <div className="header__container">
                          {bathroom.map((element, index) => {
                            return (
                              <Row key={index}>
                                <Row>
                                  <span>Position: {element.position}</span>
                                  {element.description && (
                                    <span>
                                      Description: {element.description}
                                    </span>
                                  )}
                                </Row>
                                <Row>
                                  {element.toilets && (
                                    <span>Toilets: {element.toilets}</span>
                                  )}
                                  {element.shower && (
                                    <span>Shower: {element.shower}</span>
                                  )}
                                  {element.bathtub && (
                                    <span>Bathtub: {element.bathtub}</span>
                                  )}
                                </Row>
                              </Row>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </Row>
                </React.Fragment>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              <Row>
                <Row>
                  <span className="accordion__subtitle">Comfort</span>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Air conditionning"
                      checked={comfortUpdate?.air_conditioning || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          air_conditioning: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Control
                      as="textarea"
                      rows={1}
                      disabled={!comfortUpdate?.air_conditioning}
                      defaultValue={comfortUpdate?.air_conditioning_description}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          air_conditioning_description: e.target.value,
                        })
                      }
                      className="container__description"
                    />
                  </Form.Group>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Pusle air heating"
                      checked={comfortUpdate?.pulse_air_heating || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          pulse_air_heating: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Control
                      as="textarea"
                      rows={1}
                      disabled={!comfortUpdate?.pulse_air_heating}
                      defaultValue={
                        comfortUpdate?.pulse_air_heating_description
                      }
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          pulse_air_heating_description: e.target.value,
                        })
                      }
                      className="container__description"
                    />
                  </Form.Group>
                  <Form.Group className="category__container mb-3 mr-2">
                    <Form.Group as={Col} md={3} className="update__label">
                      <InputGroup>
                        <Form.Control
                          placeholder="Hot water capacity"
                          defaultValue={comfortUpdate?.hot_water_capacity}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              hot_water_capacity: e.target.value,
                            })
                          }
                        />
                        <InputGroup.Text>L</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} className="update__label">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!comfortUpdate?.hot_water_capacity}
                        defaultValue={
                          comfortUpdate?.hot_water_capacity_description
                        }
                        onChange={(e) =>
                          setComfortUpdate({
                            ...comfortUpdate,
                            hot_water_capacity_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="category__container mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Deck shower"
                      checked={comfortUpdate?.deck_shower || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          deck_shower: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Pressurized water"
                      checked={comfortUpdate?.pressurized_water || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          pressurized_water: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Water maker"
                      checked={comfortUpdate?.water_maker || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          water_maker: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Mooring Winch"
                      checked={comfortUpdate?.ice_maker || false}
                      onChange={(e) =>
                        setComfortUpdate({
                          ...comfortUpdate,
                          ice_maker: e.target.checked,
                        })
                      }
                      className="container__checkbox"
                    />
                  </Form.Group>
                </Row>
                {cabinsUpdate &&
                  cabinsUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Cabins {index + 1}
                          </span>
                          <Button
                            variant="danger"
                            onClick={() => deleteCabin(element.id)}
                            className="canDelete__button"
                          >
                            <FaRegTrashAlt />
                          </Button>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={2}>
                              Type
                            </Form.Label>
                            <Form.Select
                              defaultValue={element.id_cabin_type}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'id_cabin_type')
                              }
                            >
                              {cabinsTypes.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={2}>
                              Position
                            </Form.Label>
                            <Form.Select
                              defaultValue={element.id_position}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'id_position')
                              }
                            >
                              {position.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={8}>
                              Quantity single bed
                            </Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              defaultValue={element.quantity_single_bed}
                              onChange={(e) =>
                                updateCabinsField(
                                  e,
                                  index,
                                  'quantity_single_bed',
                                )
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="Air conditioning"
                              checked={element.air_conditioning || false}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'air_conditioning')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="Pusle air heating"
                              checked={element.pulse_air_heating || false}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'pulse_air_heating')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={2} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="Double bed"
                              checked={element.double_bed || false}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'double_bed')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              disabled={!element.double_bed}
                              defaultValue={element.double_bed_description}
                              onChange={(e) =>
                                updateCabinsField(
                                  e,
                                  index,
                                  'double_bed_description',
                                )
                              }
                              className="container__description"
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={2} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="Hifi"
                              checked={element.hifi || false}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'hifi')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              disabled={!element.hifi}
                              defaultValue={element.hifi_description}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'hifi_description')
                              }
                              className="container__description"
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={2} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="TV"
                              checked={element.tv || false}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'tv')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              disabled={!element.tv}
                              defaultValue={element.tv_description}
                              onChange={(e) =>
                                updateCabinsField(e, index, 'tv_description')
                              }
                              className="container__description"
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addCabins.map((element, index) => {
                    return (
                      <AddCabin
                        key={index}
                        cabinsTypes={cabinsTypes}
                        position={position}
                        cabin={element}
                        deleteItem={deleteObjectCabin}
                      />
                    );
                  })}
                  <Button onClick={addNewCabin} className="update__addButton">
                    <IoMdAddCircleOutline />
                    <span>Add new Cabin</span>
                  </Button>
                </Row>
                {kitchensUpdate &&
                  kitchensUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Kitchens {index + 1}
                          </span>
                          <Button
                            variant="danger"
                            onClick={() => deleteKitchen(element.id)}
                            className="canDelete__button"
                          >
                            <FaRegTrashAlt />
                          </Button>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={4} className="update__label">
                            <Form.Label column md={3}>
                              Hotplates
                            </Form.Label>
                            <Form.Select
                              value={
                                element.id_hotplates === null
                                  ? 0
                                  : element.id_hotplates
                              }
                              onChange={(e) =>
                                updateKitchensField(e, index, 'id_hotplates')
                              }
                            >
                              {hotplates.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              disabled={
                                element.id_hotplates === null ||
                                element.id_hotplates === '0'
                              }
                              defaultValue={element.hotplates_description}
                              onChange={(e) =>
                                updateKitchensField(
                                  e,
                                  index,
                                  'hotplates_description',
                                )
                              }
                              className="container__description"
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={4} className="update__label">
                            <Form.Label column md={6}>
                              Quantity oven
                            </Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              defaultValue={element.quantity_oven}
                              disabled={
                                element.id_oven === null ||
                                element.id_oven === '0'
                              }
                              onChange={(e) =>
                                updateKitchensField(e, index, 'quantity_oven')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={4} className="update__label">
                            <Form.Label column md={3}>
                              Oven
                            </Form.Label>
                            <Form.Select
                              value={
                                element.id_oven === null ? 0 : element.id_oven
                              }
                              onChange={(e) =>
                                updateKitchensField(e, index, 'id_oven')
                              }
                            >
                              {oven.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label="Microwave"
                              checked={element.microwave || false}
                              onChange={(e) =>
                                updateKitchensField(e, index, 'microwave')
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={6}>
                              Quantity fridge
                            </Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              defaultValue={element.quantity_fridge}
                              onChange={(e) =>
                                updateKitchensField(e, index, 'quantity_fridge')
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column md={6}>
                              Quantity freezer
                            </Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              defaultValue={element.quantity_freezer}
                              onChange={(e) =>
                                updateKitchensField(
                                  e,
                                  index,
                                  'quantity_freezer',
                                )
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Control
                              as="textarea"
                              rows={1}
                              defaultValue={element.description}
                              onChange={(e) =>
                                updateKitchensField(e, index, 'description')
                              }
                              className="container__description"
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addKitchens.map((element, index) => {
                    return (
                      <AddKitchen
                        key={index}
                        hotplates={hotplates}
                        oven={oven}
                        kitchen={element}
                        deleteItem={deleteObjectKitchen}
                      />
                    );
                  })}
                  <Button onClick={addNewKitchen} className="update__addButton">
                    <IoMdAddCircleOutline />
                    <span>Add new Kitchen</span>
                  </Button>
                </Row>
                {bathroomUpdate &&
                  bathroomUpdate.map((element, index) => {
                    return (
                      <Row key={index}>
                        <Col className="update__canDelete">
                          <span className="accordion__subtitle">
                            Bathroom {index + 1}
                          </span>
                          <Button
                            variant="danger"
                            onClick={() => deleteBathroom(element.id)}
                            className="canDelete__button"
                          >
                            <FaRegTrashAlt />
                          </Button>
                        </Col>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={4} className="update__label">
                            <Form.Label column md={3}>
                              Position
                            </Form.Label>
                            <Form.Select
                              defaultValue={element.id_position}
                              onChange={(e) =>
                                updateBathroomField(e, index, 'id_position')
                              }
                            >
                              {position.map((element, index) => {
                                return (
                                  <option key={index} value={element.id}>
                                    {element.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column>Description</Form.Label>
                            <Form.Control
                              placeholder="Description"
                              defaultValue={element.description}
                              onChange={(e) =>
                                updateBathroomField(e, index, 'description')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column>Toilets</Form.Label>
                            <Form.Control
                              placeholder="Toilets"
                              defaultValue={element.toilets}
                              onChange={(e) =>
                                updateBathroomField(e, index, 'toilets')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column>Shower</Form.Label>
                            <Form.Control
                              placeholder="Shower"
                              defaultValue={element.shower}
                              onChange={(e) =>
                                updateBathroomField(e, index, 'shower')
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="update__label">
                            <Form.Label column>Bathtub</Form.Label>
                            <Form.Control
                              placeholder="Bathtub"
                              defaultValue={element.bathtub}
                              onChange={(e) =>
                                updateBathroomField(e, index, 'bathtub')
                              }
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                    );
                  })}
                <Row>
                  {addBathrooms.map((element, index) => {
                    return (
                      <AddBathroom
                        key={index}
                        position={position}
                        bathroom={element}
                        deleteItem={deleteObjectBathroom}
                      />
                    );
                  })}
                  <Button
                    onClick={addNewBathroom}
                    className="update__addButton"
                  >
                    <IoMdAddCircleOutline />
                    <span>Add new Bathroom</span>
                  </Button>
                </Row>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header onClick={() => handleHeader(5)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Cover</span>
              <hr />
              {isDisplay['5'] && (
                <React.Fragment>
                  {cover && (
                    <React.Fragment>
                      <Row>
                        <span className="col-4">
                          Winter cover: {cover.winter_cover ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Sun cover: {cover.sun_cover ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Windscreen cover:{' '}
                          {cover.windscreen_cover ? 'Yes' : 'No'}
                        </span>
                      </Row>
                      <Row>
                        <span className="col-4">
                          Sprayhood: {cover.sprayhood ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Boom cover: {cover.boom_cover ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Cockpit upholstery:{' '}
                          {cover.cockpit_upholstery ? 'Yes' : 'No'}
                        </span>
                      </Row>
                      <Row>
                        <span className="col-4">
                          Camping kit: {cover.camping_kit ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Bimini top: {cover.bimini_top ? 'Yes' : 'No'}
                        </span>
                        <span className="col-4">
                          Sun lounger: {cover.sun_lounger ? 'Yes' : 'No'}
                        </span>
                      </Row>
                      <Row>
                        {cover.description && (
                          <span className="col-12">
                            Description: {cover.description}
                          </span>
                        )}
                      </Row>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              <Row className="mb-3">
                <Form.Group className="category__container mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Winter cover"
                    checked={coverUpdate?.winter_cover || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        winter_cover: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Sun cover"
                    checked={coverUpdate?.sun_cover || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        sun_cover: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Windscreen cover"
                    checked={coverUpdate?.windscreen_cover || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        windscreen_cover: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                </Form.Group>
                <Form.Group className="category__container mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Sprayhood"
                    checked={coverUpdate?.sprayhood || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        sprayhood: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Boom cover"
                    checked={coverUpdate?.boom_cover || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        boom_cover: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Cockpit upholstery"
                    checked={coverUpdate?.cockpit_upholstery || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        cockpit_upholstery: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                </Form.Group>
                <Form.Group className="category__container mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Camping kit"
                    checked={coverUpdate?.camping_kit || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        camping_kit: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Bimini top"
                    checked={coverUpdate?.bimini_top || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        bimini_top: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Sun lounger"
                    checked={coverUpdate?.sun_lounger || false}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        sun_lounger: e.target.checked,
                      })
                    }
                    className="container__checkbox"
                  />
                </Form.Group>
                <Form.Group className="category__container mb-3">
                  <Form.Control
                    as="textarea"
                    rows={1}
                    defaultValue={coverUpdate?.description}
                    onChange={(e) =>
                      setCoverUpdate({
                        ...coverUpdate,
                        description: e.target.value,
                      })
                    }
                    className="container__description"
                  />
                </Form.Group>
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        {details && details.typology.includes('sail') && (
          <Accordion.Item eventKey="6">
            <Accordion.Header onClick={() => handleHeader(6)} as="div">
              <section className="accordion__header">
                <span className="accordion__title">
                  Sails / Rigging / Fittings
                </span>
                <hr />
                {isDisplay['6'] && (
                  <Row>
                    {sails && sails.length > 0 && (
                      <React.Fragment>
                        <span className="accordion__subtitle">Sails</span>
                        <div className="header__container sails__container">
                          {sails.map((element, index) => {
                            return (
                              <Row key={index} className="container__item">
                                <span className="item__type">
                                  {element.type}
                                </span>
                                {element.brand && (
                                  <span>Brand: {element.brand}</span>
                                )}
                                {element.area && (
                                  <span>Area: {element.area}</span>
                                )}
                                {element.year && (
                                  <span>Year: {element.year}</span>
                                )}
                                {element.description && (
                                  <span>
                                    Description: {element.description}
                                  </span>
                                )}
                              </Row>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                    {rigging && (
                      <React.Fragment>
                        <span className="accordion__subtitle">Rigging</span>
                        <Row>
                          <Row>
                            <span className="col-4">
                              Genoa winder:{' '}
                              {rigging.genoa_winder ? 'Yes' : 'No'}
                            </span>
                            {rigging.genoa_winder && (
                              <span className="col-8">
                                {rigging.genoa_winder_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              Furling mainsail:{' '}
                              {rigging.furling_mainsail ? 'Yes' : 'No'}
                            </span>
                            {rigging.furling_mainsail && (
                              <span className="col-8">
                                {rigging.furling_mainsail_description}
                              </span>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              Lazy bag: {rigging.lazy_bag ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              Lazy jack: {rigging.lazy_jacks ? 'Yes' : 'No'}
                            </span>
                          </Row>
                        </Row>
                      </React.Fragment>
                    )}
                    {fittings && (
                      <React.Fragment>
                        <span className="accordion__subtitle">Fittings</span>
                        <Row>
                          <span className="col-3">
                            Spinnaker: {fittings.spinnaker ? 'Yes' : 'No'}
                          </span>
                          <span className="col-3">
                            Releasable stay:{' '}
                            {fittings.releasable_stay ? 'Yes' : 'No'}
                          </span>
                          <span className="col-3">
                            Hydraulic power station:{' '}
                            {fittings.hydraulic_power_station ? 'Yes' : 'No'}
                          </span>
                        </Row>
                        <Row>
                          <span className="col-3">
                            Rod kicker: {fittings.rod_kicker ? 'Yes' : 'No'}
                          </span>
                          <span className="col-3">
                            Spinnaker boom:{' '}
                            {fittings.spinnaker_boom ? 'Yes' : 'No'}
                          </span>
                          <span className="col-3">
                            Jibboom: {fittings.jibboom ? 'Yes' : 'No'}
                          </span>
                          <span className="col-3">
                            Electric winch:{' '}
                            {fittings.electric_winch ? 'Yes' : 'No'}
                          </span>
                        </Row>
                        <Row>
                          {fittings.description && (
                            <span className="col-12">
                              Description: {fittings.description}
                            </span>
                          )}
                        </Row>
                      </React.Fragment>
                    )}
                  </Row>
                )}
              </section>
            </Accordion.Header>
            {user && (
              <Accordion.Body className="accordion__update">
                <Row>
                  {sailsUpdate &&
                    sailsUpdate.map((element, index) => {
                      return (
                        <Row key={index}>
                          <Col className="update__canDelete">
                            <span className="accordion__subtitle">
                              Sail {index + 1}
                            </span>
                            <Button
                              variant="danger"
                              onClick={() => deleteSail(element.id)}
                              className="canDelete__button"
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </Col>
                          <Row className="mb-3">
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={2}>
                                Type
                              </Form.Label>
                              <Form.Select
                                value={element.id_sail_type}
                                onChange={(e) =>
                                  updateSailsField(e, index, 'id_sail_type')
                                }
                              >
                                {sailsTypes.map((element, index) => {
                                  return (
                                    <option key={index} value={element.id}>
                                      {element.value}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={2}>
                                Brand
                              </Form.Label>
                              <Form.Control
                                placeholder="Brand"
                                defaultValue={element.brand}
                                onChange={(e) =>
                                  updateSailsField(e, index, 'brand')
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={2}>
                                Area
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  placeholder="Area"
                                  defaultValue={element.area}
                                  onChange={(e) =>
                                    updateSailsField(e, index, 'area')
                                  }
                                />
                                <InputGroup.Text>m²</InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group
                              as={Col}
                              md={2}
                              className="update__label"
                            >
                              <Form.Label column md={4}>
                                Year
                              </Form.Label>
                              <Form.Control
                                placeholder="Year"
                                defaultValue={element.year}
                                onChange={(e) =>
                                  updateSailsField(e, index, 'year')
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column>Description</Form.Label>
                              <Form.Control
                                placeholder="Description"
                                defaultValue={element.description}
                                onChange={(e) =>
                                  updateSailsField(e, index, 'description')
                                }
                              />
                            </Form.Group>
                          </Row>
                        </Row>
                      );
                    })}
                  <Row>
                    {addSails.map((element, index) => {
                      return (
                        <AddSail
                          key={index}
                          sailsTypes={sailsTypes}
                          sail={element}
                          deleteItem={deleteObjectSail}
                        />
                      );
                    })}
                    <Button onClick={addNewSail} className="update__addButton">
                      <IoMdAddCircleOutline />
                      <span>Add new Sail</span>
                    </Button>
                  </Row>
                  <React.Fragment>
                    <span className="row accordion__subtitle">Rigging</span>
                    <Row className="mb-3">
                      <Form.Group as={Col} md={2} className="update__label">
                        <Form.Check
                          type="checkbox"
                          label="Genoa Winder"
                          checked={riggingUpdate?.genoa_winder || false}
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              genoa_winder: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Control
                          placeholder="Description"
                          disabled={!riggingUpdate?.genoa_winder}
                          defaultValue={riggingUpdate?.genoa_winder_description}
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              genoa_winder_description: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md={2} className="update__label">
                        <Form.Check
                          type="checkbox"
                          label="Furling mainsail"
                          checked={riggingUpdate?.furling_mainsail || false}
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              furling_mainsail: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Control
                          placeholder="Description"
                          disabled={!riggingUpdate?.furling_mainsail}
                          defaultValue={
                            riggingUpdate?.furling_mainsail_description
                          }
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              furling_mainsail_description: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label="Lazy bag"
                          checked={riggingUpdate?.lazy_bag || false}
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              lazy_bag: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Lazy jack"
                          checked={riggingUpdate?.lazy_jacks || false}
                          onChange={(e) =>
                            setRiggingUpdate({
                              ...riggingUpdate,
                              lazy_jacks: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                    </Row>
                  </React.Fragment>
                  <React.Fragment>
                    <span className="row accordion__subtitle">Fittings</span>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label="Spinnaker"
                          checked={fittingsUpdate?.spinnaker || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              spinnaker: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Releasable stay"
                          checked={fittingsUpdate?.releasable_stay || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              releasable_stay: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Hydraulic power station"
                          checked={
                            fittingsUpdate?.hydraulic_power_station || false
                          }
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              hydraulic_power_station: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label="Rod kicker"
                          checked={fittingsUpdate?.rod_kicker || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              rod_kicker: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Spinnaker boom"
                          checked={fittingsUpdate?.spinnaker_boom || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              spinnaker_boom: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Jibboom"
                          checked={fittingsUpdate?.jibboom || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              jibboom: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Electric winch"
                          checked={fittingsUpdate?.electric_winch || false}
                          onChange={(e) =>
                            setFittingsUpdate({
                              ...fittingsUpdate,
                              electric_winch: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                    </Row>
                  </React.Fragment>
                </Row>
              </Accordion.Body>
            )}
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="7">
          <Accordion.Header onClick={() => handleHeader(7)} as="div">
            <section className="accordion__header">
              <span className="accordion__title">Security</span>
              <hr />
              {isDisplay['7'] && (
                <React.Fragment>
                  {security && (
                    <React.Fragment>
                      <Row>
                        <span className="col-4">
                          Equipment: {security.equipment}
                        </span>
                        <span className="col-4">
                          Survival: {security.survival ? 'Yes' : 'No'}
                        </span>
                        {security.survival_revision && (
                          <span className="col-4">
                            Revision: {security.survival_revision}
                          </span>
                        )}
                      </Row>
                      <Row>
                        {security.survival_description && (
                          <span className="col-12">
                            Description: {security.survival_description}
                          </span>
                        )}
                      </Row>
                    </React.Fragment>
                  )}
                  {securityCategory.length > 0 && (
                    <Row>
                      <span>
                        Category:{' '}
                        {securityCategory.map((element, index) => {
                          return (
                            <React.Fragment key={index}>
                              {element.category}-{element.quantity_people}{' '}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    </Row>
                  )}
                </React.Fragment>
              )}
            </section>
          </Accordion.Header>
          {user && (
            <Accordion.Body className="accordion__update">
              <Row>
                <React.Fragment>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="update__label">
                      <Form.Label column md={2}>
                        Equipment
                      </Form.Label>
                      <Form.Select
                        // defaultValue={securityUpdate?.id_equipment}
                        value={securityUpdate?.id_equipment}
                        onChange={(e) =>
                          setSecurityUpdate({
                            ...securityUpdate,
                            id_equipment: e.target.value,
                          })
                        }
                      >
                        {securityEquipment?.map((element, index) => {
                          return (
                            <option key={index} value={element.id}>
                              {element.value}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md={2} className="update__label">
                      <Form.Check
                        type="checkbox"
                        label="Survival"
                        checked={securityUpdate?.survival || false}
                        onChange={(e) =>
                          setSecurityUpdate({
                            ...securityUpdate,
                            survival: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={3} className="update__label">
                      <Form.Label column md={4}>
                        Revision
                      </Form.Label>
                      <Form.Control
                        placeholder="Revision"
                        disabled={!securityUpdate?.survival}
                        defaultValue={securityUpdate?.survival_revision}
                        onChange={(e) =>
                          setSecurityUpdate({
                            ...securityUpdate,
                            survival_revision: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="update__label">
                      <Form.Control
                        placeholder="Description"
                        disabled={!securityUpdate?.survival}
                        defaultValue={securityUpdate?.survival_description}
                        onChange={(e) =>
                          setSecurityUpdate({
                            ...securityUpdate,
                            survival_description: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </React.Fragment>
                {securityCategoryList && (
                  <Row className="d-flex justify-content-center">
                    <Row className="mb-3">
                      <Form.Label column md={2}>
                        Category:{' '}
                      </Form.Label>
                      <Table>
                        <thead>
                          <tr>
                            {securityCategoryList
                              .sort((a, b) => a.id - b.id)
                              .map((element, index) => {
                                return <th key={index}>{element.value}</th>;
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {securityCategoryList.map((element, index) => {
                              return (
                                <td key={index}>
                                  <Form.Control
                                    defaultValue={
                                      securityCertifUpdate
                                        ? securityCertifUpdate.find(
                                            (item) =>
                                              item.id_category === element.id,
                                          ) &&
                                          securityCertifUpdate.find(
                                            (item) =>
                                              item.id_category === element.id,
                                          ).quantity_people
                                        : null
                                    }
                                    onChange={(e) =>
                                      updateCategoryField(
                                        element.id,
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  </Row>
                )}
              </Row>
            </Accordion.Body>
          )}
        </Accordion.Item>
        {boat && (
          <Accordion.Item eventKey="8">
            <Accordion.Header as="div">
              <section className="accordion__header">
                <span className="accordion__title">Observations</span>
                <hr />
              </section>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Control
                as="textarea"
                rows={2}
                defaultValue={boat?.observations}
                onChange={(e) => {
                  setBoatUpdate({
                    ...boatUpdate,
                    observations: e.target.value,
                  });
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {user && (
          <Accordion.Item eventKey="9">
            <Accordion.Header as="div">
              <section className="accordion__header">
                <span className="accordion__title">People</span>
                <hr />
              </section>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="mb-3">
                <span>Owner</span>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Lastname</th>
                      <th>Firstname</th>
                      <th>Company</th>
                      <th>Phone</th>
                      <th>Shares</th>
                      <th>Privileged</th>
                      <th>Commission</th>
                      <th>Link</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  {(person.length > 0 || addingOwner.length > 0) && (
                    <tbody>
                      {person &&
                        person
                          .map((pers) => {
                            const boatInfo = people?.find(
                              (pb) => pb.id_people === pers.id,
                            );
                            return {
                              ...pers,
                              status: boatInfo.id_status || null,
                              shares: boatInfo.shares || null,
                              contact: boatInfo.contact || 0,
                              commission: boatInfo.commission / 100 || null,
                            };
                          })
                          .filter((x) => x.status === 1)
                          .map((element, index) => {
                            return (
                              <tr key={index}>
                                <td>{element.lastname}</td>
                                <td>{element.firstname}</td>
                                <td>{element.company}</td>
                                <td>{element.phone}</td>
                                <td>
                                  <InputGroup size="sm">
                                    <Form.Control
                                      size="sm"
                                      defaultValue={element.shares}
                                      onChange={(e) =>
                                        handleUpdatePeople(
                                          element.id,
                                          'shares',
                                          Number(e.target.value),
                                        )
                                      }
                                    />
                                    <InputGroup.Text>%</InputGroup.Text>
                                  </InputGroup>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    defaultValue={element.contact}
                                    variant={
                                      element.contact ? 'success' : 'danger'
                                    }
                                    onClick={() =>
                                      handleUpdatePeople(
                                        element.id,
                                        'contact',
                                        element.contact ? 0 : 1,
                                      )
                                    }
                                  >
                                    {element.contact ? 'Yes' : 'No'}
                                  </Button>
                                </td>
                                <td>
                                  <InputGroup size="sm">
                                    <Form.Control
                                      size="sm"
                                      defaultValue={element.commission}
                                      onChange={(e) =>
                                        handleUpdatePeople(
                                          element.id,
                                          'commission',
                                          Number(e.target.value),
                                        )
                                      }
                                    />
                                    <InputGroup.Text>%</InputGroup.Text>
                                  </InputGroup>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="info"
                                    as={Link}
                                    to={`/people/${element.id}`}
                                  >
                                    <FiExternalLink />
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeletePeople(element.id)
                                    }
                                  >
                                    <FaRegTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      {addingOwner?.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td>{element.lastname}</td>
                            <td>{element.firstname}</td>
                            <td>{element.company}</td>
                            <td>{element.phone}</td>
                            <td>
                              <Form.Control
                                size="sm"
                                defaultValue={element.shares}
                                onChange={(e) =>
                                  handleUpdatePeople(
                                    element.id,
                                    'shares',
                                    Number(e.target.value),
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                defaultValue={element.contact}
                                variant={element.contact ? 'success' : 'danger'}
                                onClick={() =>
                                  handleUpdatePeople(
                                    element.id,
                                    'contact',
                                    element.contact ? 0 : 1,
                                  )
                                }
                              >
                                {element.contact ? 'Yes' : 'No'}
                              </Button>
                            </td>
                            <td>
                              <InputGroup size="sm">
                                <Form.Control
                                  size="sm"
                                  defaultValue={element.commission / 100}
                                  onChange={(e) =>
                                    handleUpdatePeople(
                                      element.id,
                                      'commission',
                                      Number(e.target.value),
                                    )
                                  }
                                />
                                <InputGroup.Text>%</InputGroup.Text>
                              </InputGroup>
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="info"
                                as={Link}
                                to={`/people/${element.id}`}
                              >
                                <FiExternalLink />
                              </Button>
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => handleDeletePeople(element.id)}
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
                <hr />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Lastname</th>
                      <th>Firstname</th>
                      <th>Company</th>
                      <th>Public price per shares</th>
                      <th>Net price per shares</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  {details && person.length > 0 && (
                    <tbody>
                      {person &&
                        person
                          .map((pers) => {
                            const boatInfo = people?.find(
                              (pb) => pb.id_people === pers.id,
                            );
                            return {
                              ...pers,
                              status: boatInfo?.id_status || null,
                              shares: boatInfo?.shares || null,
                              contact: boatInfo?.contact || 0,
                              commission: boatInfo?.commission / 10000 || null,
                            };
                          })
                          .filter((x) => x.status === 1)
                          .map((element, index) => {
                            return (
                              <tr key={index}>
                                <td>{element.lastname}</td>
                                <td>{element.firstname}</td>
                                <td>{element.company}</td>
                                <td>
                                  {new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    maximumFractionDigits: 0,
                                  }).format(
                                    (details.price * element.shares) / 100,
                                  )}
                                </td>
                                <td>
                                  {new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    maximumFractionDigits: 0,
                                  }).format(
                                    ((details.price -
                                      details.price * element.commission) *
                                      element.shares) /
                                      100,
                                  )}
                                </td>
                                <td>
                                  {new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    maximumFractionDigits: 0,
                                  }).format(
                                    (details.price *
                                      element.commission *
                                      element.shares) /
                                      100,
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  )}
                </Table>
                <hr />
                <span>Leads</span>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Lastname</th>
                      <th>Firstname</th>
                      <th>Company</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Link</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  {(person.length > 0 || addingLead.length > 0) && (
                    <tbody>
                      {person &&
                        person
                          .map((pers) => {
                            const boatInfo = people?.find(
                              (pb) => pb.id_people === pers.id,
                            );
                            return {
                              ...pers,
                              status: boatInfo?.id_status || null,
                              shares: boatInfo?.shares || null,
                            };
                          })
                          .filter((x) => x.status === 2)
                          .map((element, index) => {
                            return (
                              <tr key={index}>
                                <td>{element.lastname}</td>
                                <td>{element.firstname}</td>
                                <td>{element.company}</td>
                                <td>{element.email}</td>
                                <td>{element.phone}</td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="info"
                                    as={Link}
                                    to={`/people/${element.id}`}
                                  >
                                    <FiExternalLink />
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeletePeople(element.id)
                                    }
                                  >
                                    <FaRegTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      {addingLead?.map((element, index) => (
                        <tr key={index}>
                          <td>{element.lastname}</td>
                          <td>{element.firstname}</td>
                          <td>{element.company}</td>
                          <td>{element.email}</td>
                          <td>{element.phone}</td>
                          <td>
                            <Button
                              size="sm"
                              variant="info"
                              as={Link}
                              to={`/people/${element.id}`}
                            >
                              <FiExternalLink />
                            </Button>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() => handleDeletePeople(element.id)}
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                <Row>
                  <Form.Group as={Col} md={7}>
                    <Dropdown className="customDropdown">
                      <Dropdown.Toggle
                        id="dropdown-custom-components"
                        className="customDropdown__button"
                      >
                        {selectedItem
                          ? `${selectedItem.firstname} ${selectedItem.lastname}, ${selectedItem.company} - ${selectedItem.email} - ${selectedItem.phone}`
                          : 'Search a person'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        as={CustomMenu}
                        onSelectItem={handleSelectItem}
                        className="customDropdown__menu"
                      />
                    </Dropdown>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Select
                      name="status"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      {statusPeople &&
                        statusPeople.map((element, index) => {
                          return (
                            <option key={index} value={element.id}>
                              {element.value}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min={0}
                        max={100}
                        placeholder="Shares"
                        onChange={(e) => setShares(Number(e.target.value))}
                        disabled={selectedStatus !== '1'}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md={1}>
                    <Button
                      variant="success"
                      onClick={handleAddPeople}
                      disabled={isButtonDisabled()}
                    >
                      <MdAddBox />
                    </Button>
                  </Form.Group>
                </Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {user && (
          <Accordion.Item eventKey="10">
            <Accordion.Header onClick={() => handleHeader(10)} as="div">
              <section className="accordion__header">
                <span className="accordion__title">Transaction</span>
                <hr />
                {isDisplay['10'] && transactionUpdate && (
                  <div>
                    <div className="transaction__people">
                      <div className="people__person">
                        <span className="person__category">Seller</span>
                        <hr />
                        {person &&
                          person
                            .map((pers) => {
                              const boatInfo = people?.find(
                                (pb) => pb.id_people === pers.id,
                              );
                              return {
                                ...pers,
                                status: boatInfo.id_status || null,
                                shares: boatInfo.shares || null,
                                contact: boatInfo.contact || 0,
                                commission: boatInfo.commission / 100 || null,
                              };
                            })
                            .filter((x) => x.status === 1)
                            .map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="transaction__people"
                                >
                                  <span>{element.lastname}</span>
                                  <span>{element.firstname}</span>
                                  {element.company && (
                                    <span>{element.company}</span>
                                  )}
                                  <span>{element.phone}</span>
                                  <span>{element.shares} %</span>
                                </div>
                              );
                            })}
                      </div>
                      <div className="people__person">
                        <span className="person__category">Buyer</span>
                        <hr />
                        {person &&
                          person
                            .filter((person) =>
                              buyerList.some((share) => share.id === person.id),
                            )
                            .map((person) => {
                              const shareInfo = buyerList.find(
                                (share) => share.id === person.id,
                              );
                              return {
                                ...person,
                                shares: shareInfo.shares,
                              };
                            })
                            .map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="transaction__people"
                                >
                                  <span>{element.lastname}</span>
                                  <span>{element.firstname}</span>
                                  {element.company && (
                                    <span>{element.company}</span>
                                  )}
                                  <span>{element.phone}</span>
                                  <span>{element.shares} %</span>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <hr />
                    <div className="transactionInfo__container">
                      <div className="container__item">
                        <span className="item__label">Price offer</span>
                        <span className="item__value">
                          {new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 0,
                          }).format(transactionUpdate.price_offer)}
                        </span>
                      </div>
                      <div className="container__item d-flex">
                        <span className="item__label">Commission</span>
                        <div className="w-50">
                          <span className="item__value">
                            {(
                              (transactionUpdate.commission /
                                transactionUpdate.price_offer) *
                              100
                            ).toFixed(2)}{' '}
                            %
                          </span>
                          <span className="item__value">
                            {new Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 0,
                            }).format(transactionUpdate.commission)}
                          </span>
                        </div>
                      </div>
                      <div className="container__item">
                        <span className="item__label">New boat name</span>
                        <span>{transactionUpdate?.new_boat_name}</span>
                      </div>
                      <div className="container__item">
                        <span className="item__label">Prepayment</span>
                        <span className="item__value">
                          {new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 0,
                          }).format(transactionUpdate.prepayment)}
                        </span>
                      </div>
                      <div className="container__item">
                        <span className="item__label">Date de compromis</span>
                        <span className="item__value">
                          {transactionUpdate.start_date_compromise_creation}
                        </span>
                      </div>
                      <div className="container__item">
                        <span className="item__label">Date d'expiration</span>
                        <span className="item__value">
                          {transactionUpdate.compromise_expiry_date}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="transaction__people">
                  <div className="people__person">
                    <span className="person__category">Seller</span>
                    <hr />
                    {person &&
                      person
                        .map((pers) => {
                          const boatInfo = people?.find(
                            (pb) => pb.id_people === pers.id,
                          );
                          return {
                            ...pers,
                            status: boatInfo.id_status || null,
                            shares: boatInfo.shares || null,
                            contact: boatInfo.contact || 0,
                            commission: boatInfo.commission / 100 || null,
                          };
                        })
                        .filter((x) => x.status === 1)
                        .map((element, index) => {
                          return (
                            <div key={index} className="transaction__people">
                              <span>{element.lastname}</span>
                              <span>{element.firstname}</span>
                              {element.company && (
                                <span>{element.company}</span>
                              )}
                              <span>{element.phone}</span>
                              <span>{element.shares} %</span>
                            </div>
                          );
                        })}
                  </div>
                  <div className="people__person">
                    <span className="person__category">Buyer</span>
                    <hr />
                    {transaction ? (
                      <div>
                        {person &&
                          person
                            .filter((person) =>
                              buyerList.some((share) => share.id === person.id),
                            )
                            .map((person) => {
                              const shareInfo = buyerList.find(
                                (share) => share.id === person.id,
                              );
                              return {
                                ...person,
                                shares: shareInfo.shares,
                              };
                            })
                            .map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="transaction__people"
                                >
                                  <span>{element.lastname}</span>
                                  <span>{element.firstname}</span>
                                  {element.company && (
                                    <span>{element.company}</span>
                                  )}
                                  <span>{element.phone}</span>
                                  <span>{element.shares} %</span>
                                </div>
                              );
                            })}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="person__container">
                          {buyerList &&
                            buyerList.map((element, index) => {
                              return (
                                <div
                                  className="d-flex justify-content-between"
                                  key={index}
                                >
                                  <Dropdown
                                    onSelect={(eventKey, e) =>
                                      handleSelectBuyer(eventKey, e, index)
                                    }
                                    className="w-50"
                                  >
                                    <Dropdown.Toggle
                                      variant="outline-primary"
                                      className="w-100"
                                    >
                                      {buyerList[index].id
                                        ? `${buyerList[index].name}`
                                        : 'Select buyer'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {person &&
                                        person
                                          .map((pers) => {
                                            const boatInfo = people?.find(
                                              (pb) => pb.id_people === pers.id,
                                            );
                                            return {
                                              ...pers,
                                              status:
                                                boatInfo?.id_status || null,
                                              shares: boatInfo?.shares || null,
                                            };
                                          })
                                          .filter((x) => x.status === 2)
                                          .map((element, index) => {
                                            return (
                                              <Dropdown.Item
                                                key={index}
                                                eventKey={element.id}
                                              >
                                                {element.lastname}{' '}
                                                {element.firstname}
                                              </Dropdown.Item>
                                            );
                                          })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <Form.Group className="w-25">
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder="Shares"
                                        value={buyerList[index].shares}
                                        onChange={(e) =>
                                          handleChangeBuyer(
                                            Number(e.target.value),
                                            index,
                                          )
                                        }
                                      />
                                      <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => handleDeleteBuyer(index)}
                                  >
                                    <FaRegTrashAlt />
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                        <Button
                          onClick={addNewBuyer}
                          className="person__addButton"
                        >
                          <IoMdAddCircleOutline />
                          <span>Add new lead</span>
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <hr />
                <div className="transactionInfo__container">
                  <Form.Group className="container__item">
                    <Form.Label className="item__label">
                      Price offer*
                    </Form.Label>
                    <InputGroup className="w-50">
                      <Form.Control
                        size="sm"
                        type="text"
                        name="price_offer"
                        value={transactionUpdate?.price_offer}
                        onChange={handleChangeTransaction}
                      />
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <div className="container__item d-flex">
                    <span className="item__label">Commission*</span>
                    <div className="w-50 d-flex gap-2">
                      <InputGroup>
                        <Form.Control
                          size="sm"
                          type="text"
                          value={commissionPercentage}
                          onChange={handlePercentageChange}
                          className="item__value"
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          size="sm"
                          type="text"
                          value={commissionMoney}
                          onChange={handleMoneyChange}
                          className="item__value"
                        />
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Group className="container__item">
                    <Form.Label className="item__label">
                      New boat name
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="new_boat_name"
                      defaultValue={transactionUpdate?.new_boat_name}
                      onChange={handleChangeTransaction}
                      className="item__value"
                    />
                  </Form.Group>
                  <Form.Group className="container__item">
                    <Form.Label className="item__label">Prepayment*</Form.Label>
                    <InputGroup className="w-50">
                      <Form.Control
                        size="sm"
                        type="text"
                        name="prepayment"
                        value={transactionUpdate?.prepayment}
                        onChange={handleChangeTransaction}
                      />
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    controlId="dateExpirationCompromis"
                    className="container__item"
                  >
                    <Form.Label className="item__label">
                      Date du compromis*
                    </Form.Label>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="start_date_compromise_creation"
                      value={transactionUpdate?.start_date_compromise_creation}
                      onChange={handleChangeTransaction}
                      className="item__value"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="dateExpirationCompromis"
                    className="container__item"
                  >
                    <Form.Label className="item__label">
                      Date d'expiration*
                    </Form.Label>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="compromise_expiry_date"
                      value={transactionUpdate?.compromise_expiry_date}
                      onChange={handleChangeTransaction}
                      className="item__value"
                    />
                  </Form.Group>
                </div>
                <hr />
                {transactionUpdate?.id && (
                  <Button
                    size="sm"
                    variant="info"
                    as={Link}
                    to={`/transactions/${transactionUpdate.id}`}
                  >
                    <FiExternalLink /> Link
                  </Button>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {tracking.length > 0 && (
          <Accordion.Item eventKey="11">
            <Accordion.Header as="div">
              <section className="accordion__header">
                <span className="accordion__title">History</span>
                <hr />
              </section>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Event type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tracking.map((element, index) => {
                      const date = new Date(element.date);
                      return (
                        <tr key={index}>
                          <td>
                            {new Intl.DateTimeFormat('fr-FR', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            }).format(date)}
                          </td>
                          <td>{element.id_events}</td>
                          <td>{element.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
      {user && boat && boatUpdate && (
        <div className="buttons__container">
          <ToastContainer position="bottom-end" className="container__toast">
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              bg={bgToast}
              autohide
            >
              <Toast.Body>
                <span className="me-auto">{textToast}</span>
              </Toast.Body>
            </Toast>
          </ToastContainer>
          <Button
            variant="success"
            type="submit"
            form="form"
            disabled={
              (JSON.stringify(boat) === JSON.stringify(boatUpdate) &&
                compareArrays(enginesDefault, enginesUpdate) &&
                addEngine.length === 0 &&
                deleteEngines.length === 0 &&
                JSON.stringify(maneuverOptions) ===
                  JSON.stringify(maneuverOptionsUpdate) &&
                compareArrays(batteries, batteriesUpdate) &&
                addBattery.length === 0 &&
                deleteBatteries.length === 0 &&
                compareArrays(generators, generatorsUpdate) &&
                addGenerator.length === 0 &&
                deleteGenerators.length === 0 &&
                JSON.stringify(solarPanel) ===
                  JSON.stringify(solarPanelUpdate) &&
                JSON.stringify(windturbine) ===
                  JSON.stringify(windturbineUpdate) &&
                JSON.stringify(electronic) ===
                  JSON.stringify(electronicUpdate) &&
                compareArrays(tanksDefault, tanksUpdate) &&
                addTanks.length === 0 &&
                deleteTanks.length === 0 &&
                JSON.stringify(comfort) === JSON.stringify(comfortUpdate) &&
                compareArrays(cabinsDefault, cabinsUpdate) &&
                addCabins.length === 0 &&
                deleteCabins.length === 0 &&
                compareArrays(kitchensDefault, kitchensUpdate) &&
                addKitchens.length === 0 &&
                deleteKitchens.length === 0 &&
                compareArrays(bathroomDefault, bathroomUpdate) &&
                addBathrooms.length === 0 &&
                deleteBathrooms.length === 0 &&
                JSON.stringify(cover) === JSON.stringify(coverUpdate) &&
                compareArrays(sailsDefault, sailsUpdate) &&
                addSails.length === 0 &&
                deleteSails.length === 0 &&
                JSON.stringify(rigging) === JSON.stringify(riggingUpdate) &&
                JSON.stringify(fittings) === JSON.stringify(fittingsUpdate) &&
                JSON.stringify(securityDefault) ===
                  JSON.stringify(securityUpdate) &&
                compareArrays(securityCertifDefault, securityCertifUpdate) &&
                addCertif.length === 0 &&
                delCertif.length === 0 &&
                addingOwner.length === 0 &&
                addingLead.length === 0 &&
                deletedPeople.length === 0 &&
                compareArrays(peopleDefault, people) &&
                JSON.stringify(transaction) ===
                  JSON.stringify(transactionUpdate) &&
                (!transaction ? buyerList.length === 0 : true)) ||
              saving
            }
            className="container__button"
          >
            {saving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <IoMdCheckmarkCircleOutline />
            )}
          </Button>
          <Button
            variant="danger"
            type="reset"
            form="form"
            disabled={
              (JSON.stringify(boat) === JSON.stringify(boatUpdate) &&
                compareArrays(enginesDefault, enginesUpdate) &&
                addEngine.length === 0 &&
                deleteEngines.length === 0 &&
                JSON.stringify(maneuverOptions) ===
                  JSON.stringify(maneuverOptionsUpdate) &&
                compareArrays(batteries, batteriesUpdate) &&
                addBattery.length === 0 &&
                deleteBatteries.length === 0 &&
                compareArrays(generators, generatorsUpdate) &&
                addGenerator.length === 0 &&
                deleteGenerators.length === 0 &&
                JSON.stringify(solarPanel) ===
                  JSON.stringify(solarPanelUpdate) &&
                JSON.stringify(windturbine) ===
                  JSON.stringify(windturbineUpdate) &&
                JSON.stringify(electronic) ===
                  JSON.stringify(electronicUpdate) &&
                compareArrays(tanksDefault, tanksUpdate) &&
                addTanks.length === 0 &&
                deleteTanks.length === 0 &&
                JSON.stringify(comfort) === JSON.stringify(comfortUpdate) &&
                compareArrays(cabinsDefault, cabinsUpdate) &&
                addCabins.length === 0 &&
                deleteCabins.length === 0 &&
                compareArrays(kitchensDefault, kitchensUpdate) &&
                addKitchens.length === 0 &&
                deleteKitchens.length === 0 &&
                compareArrays(bathroomDefault, bathroomUpdate) &&
                addBathrooms.length === 0 &&
                deleteBathrooms.length === 0 &&
                JSON.stringify(cover) === JSON.stringify(coverUpdate) &&
                compareArrays(sailsDefault, sailsUpdate) &&
                addSails.length === 0 &&
                deleteSails.length === 0 &&
                JSON.stringify(rigging) === JSON.stringify(riggingUpdate) &&
                JSON.stringify(fittings) === JSON.stringify(fittingsUpdate) &&
                JSON.stringify(securityDefault) ===
                  JSON.stringify(securityUpdate) &&
                compareArrays(securityCertifDefault, securityCertifUpdate) &&
                addCertif.length === 0 &&
                delCertif.length === 0 &&
                addingOwner.length === 0 &&
                addingLead.length === 0 &&
                deletedPeople.length === 0 &&
                compareArrays(peopleDefault, people) &&
                JSON.stringify(transaction) ===
                  JSON.stringify(transactionUpdate) &&
                (!transaction ? buyerList.length === 0 : true)) ||
              saving
            }
            className="container__button"
          >
            {saving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <IoMdCloseCircleOutline />
            )}
          </Button>
        </div>
      )}
    </div>
  );
}
