// import libraries
import React from 'react';
// import css
import '../../assets/styles/settings.scss';

/*
Settings page
WIP
Display nothing for the moment.
*/

function Settings () {
    
    return (
        <div className='d-flex flex-column align-items-center justify-content-center dashboard'>
            <h1 className='mb-5'>Settings</h1>
            <p>WORK IN PROGRESS</p>
        </div>
    );
}
// Export to call it up in app.jsx
export default Settings;