// import libraries
import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
// import css
import '../assets/styles/customDropdown.scss';

export default function CustomDropdown({
  items,
  status,
  setSelectedItem,
  buttonDefault,
}) {
  const [isSelected, setIsSelected] = useState(buttonDefault);
  // CustomMenu Component
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                (child.props['data-model'] &&
                  child.props['data-model']
                    .toLowerCase()
                    .includes(value.toLowerCase())),
            )}
          </ul>
        </div>
      );
    },
  );

  const handleSelect = (eventKey, event) => {
    setIsSelected(event.target.textContent);
    setSelectedItem(items.find((item) => item.id.toString() === eventKey));
  };

  const getStatusValue = (statusId) => {
    const statusItem = status.find((item) => item.id === statusId);
    return statusItem ? statusItem.value : 'Unknown';
  };

  return (
    <Dropdown onSelect={handleSelect} className="customDropdown">
      <Dropdown.Toggle id="dropdown-custom-components" className='customDropdown__button'>
        {isSelected}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className="customDropdown__menu">
        {items.map((element) => {
          return (
            <Dropdown.Item
              eventKey={element.id}
              key={element.id}
              data-model={element.model}
            >
              {element.id} | {element.brand} - {element.model} |{' '}
              {getStatusValue(element.status)} -{' '}
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              }).format(element.price)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
