// import libraries
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from "react-icons/fa";

/*
Add bathroom component
@params :
    - position {array of objects} => all position possible.
    - bathroom {object} => new bathroom to add.
    - deleteItem {function} => delete this bathroom of array.

All fields needed to create a new bathroom.
*/

export default function AddBathroom({position, bathroom, deleteItem}) {

    return (
        <React.Fragment>
            <Col className='update__canDelete'>
                <span className='accordion__subtitle'>New Bathroom</span>
                <Button 
                    variant='danger' 
                    onClick={() => deleteItem(bathroom.id)}
                    className='canDelete__button'>
                    <FaRegTrashAlt />
                </Button>
            </Col>
            <Row className='mb-3'>
                <Form.Group as={Col} md={4} className='update__label'>
                    <Form.Label column md={3}>Position</Form.Label>
                    <Form.Select 
                        onChange={(e) => bathroom.id_position = e.target.value}>
                        {position.map((element, index) => {
                            return (
                                <option key={index} value={element.id}>{element.value}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column>Description</Form.Label>
                    <Form.Control 
                        placeholder='Description'
                        onChange={(e) => bathroom.description = e.target.value} />
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column>Toilets</Form.Label>
                    <Form.Control 
                        placeholder='Toilets'
                        onChange={(e) => bathroom.toilets = e.target.value} />
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column>Shower</Form.Label>
                    <Form.Control 
                        placeholder='Shower'
                        onChange={(e) => bathroom.shower = e.target.value} />
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column>Bathtub</Form.Label>
                    <Form.Control 
                        placeholder='Bathtub'
                        onChange={(e) => bathroom.bathtub = e.target.value} />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
};