// import libraries
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
// import utils
import { getDataID } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/transactionCorePerson.scss';

/*
TransactionCorePerson Component
@params :
    - id {integer} => person id.
    - status {string} => status of the person to display (Seller or Acquirer).
    - shares {integer} => percentage of boat shares.
    - id_boat {integer} => id of transaction boat.
    - isEditing {boolean} => editable mode to switch to input.
Display basic details of a person in a transaction
*/

export default function TransactionCorePerson({
  element,
  id_transaction,
  status,
  shares,
  priceOffer,
  id_boat,
  isEditing,
  commissionUpdate,
  setCommissionUpdate,
  commissionCalcul,
  sharesUpdate,
  setSharesUpdate,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [person, setPerson] = useState();
  const [commissionMoney, setCommissionMoney] = useState();
  const [commissionPercentage, setCommissionPercentage] = useState();
  const [euroProrata, setEuroProrata] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getDataID(
          API_ROUTES.PEOPLE.GET.PERSON,
          element.id_people,
        );
        setData(response.data);
        if (id_boat) {
          const getPeople = await getDataID(
            API_ROUTES.BOATS.GET.PEOPLE,
            id_boat,
          );
          setPerson(
            getPeople.data.filter(
              (person) => person.id_people === element.id_people,
            ),
          );
          setCommissionMoney(
            getPeople.data.filter(
              (person) => person.id_people === element.id_people,
            )[0].commission,
          );
          commissionCalcul(
            element.id_people,
            getPeople.data.filter(
              (person) => person.id_people === element.id_people,
            )[0].commission,
          );
          const euroProrataCalcul =
            (priceOffer *
              getPeople.data.filter(
                (person) => person.id_people === element.id_people,
              )[0].shares) /
            100;
          setEuroProrata(euroProrataCalcul);
          setCommissionPercentage(
            (
              (getPeople.data.filter(
                (person) => person.id_people === element.id_people,
              )[0].commission *
                100) /
              euroProrataCalcul
            )
              .toFixed(2)
              .toString(),
          );
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        } else {
          console.log('people index L120 error ', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [navigate, element.id_people, id_boat]);

  const updateCommission = (newCommission) => {
    const Arr = JSON.parse(JSON.stringify(commissionUpdate));
    const index = Arr.map((item) => item.id_people).indexOf(element.id_people);
    if (index === -1) {
      const newCommissionChange = {
        id_people: element.id_people,
        id_boats: id_boat,
        commission: newCommission,
      };
      Arr.push(newCommissionChange);
    } else {
      Arr[index].commission = newCommission;
    }
    setCommissionUpdate(Arr);
  };

  const handleMoneyChange = (e) => {
    const newCommission = Number(e.target.value.replace(/[^0-9]/g, ''));
    setCommissionMoney(newCommission);
    const newRate = (newCommission / euroProrata) * 100;
    setCommissionPercentage(newRate.toFixed(4));
    updateCommission(newCommission);
    commissionCalcul(element.id_people, newCommission);
  };

  const handlePercentageChange = (e) => {
    const newRate = e.target.value;
    setCommissionPercentage(newRate);
    const newCommission = (newRate / 100) * euroProrata;
    setCommissionMoney(newCommission.toFixed(0));
    updateCommission(newCommission);
    commissionCalcul(element.id_people, newCommission);
  };

  const handleChangeBuyerShares = (e) => {
    const Arr = JSON.parse(JSON.stringify(sharesUpdate));
    const index = Arr.map((item) => item.id_people).indexOf(element.id_people);
    if (index === -1) {
      const updateShares = {
        id_transactions: id_transaction,
        id_people: element.id_people,
        shares: e.target.value * 100,
      };
      Arr.push(updateShares);
    } else {
      Arr[index].shares = e.target.value * 100;
    }
    setSharesUpdate(Arr);
  };

  return (
    <React.Fragment>
      {loading ? (
        <h2 className="transactionCore__loading">Loading...</h2>
      ) : (
        <div className="transactionCore__person">
          <div className="transactionCore__person__title">
            <div className="transactionCore__person__title__text">
              <h2>{status} -</h2>
              <h3>
                {data?.company
                  ? `${data.company}, ${data?.lastname} ${data?.firstname}`
                  : `${data?.lastname} ${data?.firstname}`}
              </h3>
            </div>
            <Button
              variant="info"
              as={Link}
              to={`/people/${element.id_people}`}
              className="transactionCore__person__title__button"
            >
              <FiExternalLink />
              Link
            </Button>
          </div>
          <div className="transactionCore__person__core">
            <div className="transactionCore__person__core__info">
              <span className="transactionCore__person__core__info__label">
                Email
              </span>
              <span>{data?.email}</span>
            </div>
            <div className="transactionCore__person__core__info">
              <span className="transactionCore__person__core__info__label">
                Phone
              </span>
              <span>{formatPhoneNumberIntl(data?.phone)}</span>
            </div>
            {shares && (
              <div className="transactionCore__person__core__info">
                <span className="transactionCore__person__core__info__label">
                  Shares to buy
                </span>
                {isEditing ? (
                  <InputGroup className="transactionCommercial__core__infoGroup__input w-50">
                    <Form.Control
                      size="sm"
                      type="text"
                      defaultValue={(element.shares / 100).toFixed(2)}
                      onChange={handleChangeBuyerShares}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <span>{(shares / 100).toFixed(2)} %</span>
                )}
              </div>
            )}
            {person && (
              <div className="transactionCore__person__core__info">
                <span className="transactionCore__person__core__info__label">
                  Shares to sell
                </span>
                <span>{person[0].shares.toFixed(2)} %</span>
              </div>
            )}
            {id_boat && person && (
              <div className="transactionCore__person__core__info">
                <span className="transactionCommercial__core__info__label">
                  Commission
                </span>
                <div className="transactionCommercial__core__infoGroup">
                  {isEditing ? (
                    <React.Fragment>
                      <InputGroup className="transactionCommercial__core__infoGroup__input">
                        <Form.Control
                          size="sm"
                          type="text"
                          value={commissionPercentage}
                          onChange={handlePercentageChange}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                      <InputGroup className="transactionCommercial__core__infoGroup__input">
                        <Form.Control
                          size="sm"
                          type="text"
                          value={commissionMoney}
                          onChange={handleMoneyChange}
                        />
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span>{`${commissionPercentage} %`}</span>
                      <span>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 0,
                        }).format(commissionMoney)}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
