// import libraries
import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getDashboard } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/dashboard.scss';

/*
Dashboard page
WIP
Display only the first name of the logged-in user. The default page.
*/

function Dashboard() {
  const navigate = useNavigate();
  // data receive of fetch
  const [data, setData] = useState([]);
  // display spinner during fetch
  const [isLoading, setLoading] = useState(false);
  const [isLog, setLog] = useState(false);

  // data fetch with user id
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        try {
          if (localStorage.getItem('token')) setLog(true);
        } catch (error) {
          console.log(error);
        }
        const response = await getDashboard(API_ROUTES.USERS.GET.DASHBOARD);
        setData(response.data);
        localStorage.setItem(
          'ids',
          JSON.stringify({
            user: response.data.user,
            agency: response.data.agency,
          }),
        );
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login'); // redirect to login page if error
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLog && (
        <div className="d-flex flex-column align-items-center justify-content-center dashboard">
          <h1 className="mb-5">Dashboard</h1>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <h2>Welcome {data.firstname}</h2>
          )}

          <p>WORK IN PROGRESS</p>
        </div>
      )}
    </React.Fragment>
  );
}
// Export to call it up in app.jsx
export default Dashboard;
