// import libraries
import React from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from "react-icons/fa";

/*
Add battery component
@params :
    - battery {object} => new battery park to add.
    - deleteItem {function} => delete this battery of array.

All fields needed to create a new battery.
*/

export default function AddBattery({battery, deleteItem}) {

    return (
        <React.Fragment>
            <Col className='update__canDelete'>
                <span className='accordion__subtitle'>New Battery Park</span>
                <Button 
                    variant='danger' 
                    onClick={() => deleteItem(battery.id)}
                    className='canDelete__button'>
                    <FaRegTrashAlt />
                </Button>
            </Col>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={3}>Quantity</Form.Label>
                    <Form.Control 
                        placeholder='Quantity'
                        onChange={(e) => battery.quantity = e.target.value} />
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={4}>Assignment</Form.Label>
                    <Form.Control 
                        placeholder='Assignment'
                        onChange={(e) => battery.assignment = e.target.value} />
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={2}>Type</Form.Label>
                    <Form.Control 
                        placeholder='Type'
                        onChange={(e) => battery.type = e.target.value} />
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={3}>Voltage</Form.Label>
                    <InputGroup>
                        <Form.Control 
                            placeholder='Voltage'
                            onChange={(e) => battery.voltage = e.target.value} />
                        <InputGroup.Text>V</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} className='update__label'>
                    <Form.Label column md={3}>Amperage</Form.Label>
                    <InputGroup>
                        <Form.Control 
                            placeholder='Amperage'
                            onChange={(e) => battery.amperage = e.target.value} />
                        <InputGroup.Text>A</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} className='update__label'></Form.Group>
            </Row>
        </React.Fragment>
    )
};