// import libraries
import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/login.scss';

/*
Login page
Login system.
All the code in the comments allows you to create a user. Don't forget to modify the backend too.
*/

function Login() {
  const navigate = useNavigate();
  // ID
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  // const [lname, setLname] = useState('');
  // const [fname, setFname] = useState('');
  // display error on submission
  const [isValid, setValid] = useState(true);
  // display spinner during fetch
  const [isLoading, setLoading] = useState(false);
  // login fetch
  const log = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await postLogin(
        API_ROUTES.USERS.POST.LOGIN,
        login,
        password,
      );
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      setValid(true);
      navigate('/');
    } catch (error) {
      console.log(error);
      setValid(false);
    } finally {
      setTimeout(() => {
        setPassword('');
        setLoading(false);
      }, 500);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center login">
      <h1 className="mb-5">Boats Solutions</h1>
      {/* Login Form */}
      <Form onSubmit={log}>
        <Form.Group className="mb-3">
          <Form.Label>Login</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => {
              setLogin(e.target.value);
            }}
            required
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </Form.Group>
        {/* to comment */}
        {/* <Form.Group className="mb-3">
          <Form.Label>lname</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => {
              setLname(e.target.value);
            }}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>fname</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => {
              setFname(e.target.value);
            }}
            required
          />
        </Form.Group> */}
        {/* Error div */}
        {isValid ? null : (
          <div className="mb-3 text-center text-danger fst-italic">
            Wrong user/password
          </div>
        )}
        {/* Button spinner */}
        {isLoading ? (
          <Button disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          </Button>
        ) : (
          <Button type="submit">Submit</Button>
        )}
      </Form>
    </div>
  );
}
// Export to call it up in app.jsx
export default Login;
