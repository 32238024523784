// Calculate the amount of annual law of francization and navigation
export function dafn(typology, year, engines, length) {
  const age = 2018 - year;
  let allowances = 0;
  let hullTax = 0;
  let engineTax = 0;
  let powerTotal = 0;
  let total = 0;

  for (let index = 0; index < engines.length; index++) {
    powerTotal = powerTotal + engines[index].fiscal_power;
  }

  if (!length || (length < 7 && powerTotal < 22)) {
    return total;
  } else {
    if (age >= 10 && age <= 20) {
      allowances = 33;
    } else if (age >= 21 && age <= 25) {
      allowances = 55;
    } else if (age > 25) {
      allowances = 80;
    }

    if (length < 7) {
      hullTax = 0;
    } else if (length >= 7 && length < 8) {
      hullTax = 77;
    } else if (length >= 8 && length < 9) {
      hullTax = 105;
    } else if (length >= 9 && length < 10) {
      hullTax = 178;
    } else if (length >= 10 && length < 11) {
      hullTax = 240;
    } else if (length >= 11 && length < 12) {
      hullTax = 274;
    } else if (length >= 12 && length < 15) {
      hullTax = 458;
    } else {
      hullTax = 886;
    }

    if (typology === 'OB' || typology === 'OB Fly') {
      for (let index = 0; index < engines.length; index++) {
        if (engines[index].fiscal_power <= 5) {
          engineTax += 0;
        } else if (
          engines[index].fiscal_power >= 6 &&
          engines[index].fiscal_power <= 8
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 14;
        } else if (
          engines[index].fiscal_power >= 9 &&
          engines[index].fiscal_power <= 10
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 16;
        } else if (
          engines[index].fiscal_power >= 11 &&
          engines[index].fiscal_power <= 20
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 35;
        } else if (
          engines[index].fiscal_power >= 21 &&
          engines[index].fiscal_power <= 25
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 40;
        } else if (
          engines[index].fiscal_power >= 26 &&
          engines[index].fiscal_power <= 50
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 44;
        } else if (
          engines[index].fiscal_power >= 51 &&
          engines[index].fiscal_power <= 99
        ) {
          engineTax += (engines[index].fiscal_power - 5) * 50;
        } else {
          engineTax += engines[index].fiscal_power * 64;
        }
      }
    } else {
      if (powerTotal <= 5) {
        engineTax = 0;
      } else if (powerTotal >= 6 && powerTotal <= 8) {
        engineTax = (powerTotal - 5) * 14;
      } else if (powerTotal >= 9 && powerTotal <= 10) {
        engineTax = (powerTotal - 5) * 16;
      } else if (powerTotal >= 11 && powerTotal <= 20) {
        engineTax = (powerTotal - 5) * 35;
      } else if (powerTotal >= 21 && powerTotal <= 25) {
        engineTax = (powerTotal - 5) * 40;
      } else if (powerTotal >= 26 && powerTotal <= 50) {
        engineTax = (powerTotal - 5) * 44;
      } else if (powerTotal >= 51 && powerTotal <= 99) {
        engineTax = (powerTotal - 5) * 50;
      } else {
        engineTax = powerTotal * 64;
      }
    }

    total = Math.ceil((hullTax + engineTax) * ((100 - allowances) / 100));
    return total;
  }
}
