// import libraries
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import components
import Sidebar from './components/sidebar';
// import pages
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Boats from './pages/boats';
import Boat from './pages/boat';
import People from './pages/people';
import Settings from './pages/settings';
import CreateBoat from './pages/createBoat';
import Person from './pages/person';
import Transactions from './pages/transactions';
import Transaction from './pages/transaction';

// App function with routes and pages
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Sidebar />}>
          <Route index element={<Dashboard />} />
          <Route path="/boats" element={<Boats />} />
          <Route path="/boats/:id" element={<Boat />} />
          <Route path="/boats/create" element={<CreateBoat />} />
          <Route path="/people" element={<People />} />
          <Route path="/people/:id" element={<Person />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transactions/:id" element={<Transaction />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
// Export to call it up in index.js
export default App;
