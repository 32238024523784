// import libraries
import React, { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Card, Form, Spinner, Modal } from 'react-bootstrap';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { IoMdAddCircle } from 'react-icons/io';
import { FaFileUpload } from 'react-icons/fa';
import { MdDelete, MdDragIndicator } from 'react-icons/md';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
// import utils
import { API_ROUTES, LOCAL_URL } from '../utils/constants';
import {
  deleteDataID,
  getDataID,
  postImagesBoat,
  putDataID,
} from '../utils/api';
// import assets
import placeholder from '../assets/images/wshipyard.avif';
// import css
import '../assets/styles/carousel.scss';
import '../assets/styles/modalImages.scss';
import '../assets/styles/cardImages.scss';

/*
CarouselDetails component
@params :
  - id {integer} => id of boat for fetch images.
  - user {boolean} => if this user own the boat.

Carousel of images with comment on the bottom.
*/

export default function CarouselDetails({ id, user }) {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [imagesAdd, setImagesAdd] = useState([]);
  const [imagesUpdate, setImagesUpdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [imagesDeleted, setImagesDeleted] = useState([]);
  const inputRef = useRef(null);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getDataID(API_ROUTES.BOATS.GET.IMAGES, id);
        if (response.status === 200) {
          setImages(response.data);
          setImagesUpdate(response.data);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          console.log('carousel getdataid L57 error 403 TODO logout ');
        } else {
          console.log('carousel getdataid L59 error: ', error);
        }
      }
    };
    fetchData();
    setLoading(false);
  }, [id]);

  const handleButton = () => {
    inputRef.current?.click();
  };

  const handleImage = async (e) => {
    const files = Array.from(e.target.files);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    files.forEach((file) => {
      if (file && file.type.startsWith('image')) {
        return imageCompression(file, options).then((compressedFile) => {
          setImagesAdd((prevSrc) => [
            ...prevSrc,
            {
              url: compressedFile,
              id: Date.now() + '-' + Math.floor(Math.random() * 1000),
            },
          ]);
        });
      }
    });
  };

  const array_move = (old_index, new_index, dataState, setDataState) => {
    if (new_index - 1 >= dataState.length) {
      var k = new_index - 1 - dataState.length + 1;
      while (k--) {
        dataState.push(undefined);
      }
    }
    dataState.splice(new_index - 1, 0, dataState.splice(old_index, 1)[0]);
    const updatedOrder = dataState.map((item, index) => ({
      ...item,
      newOrder: index + 1,
    }));
    setDataState(updatedOrder.filter((element) => element !== undefined));
  };

  const handleDragDropAdd = (results) => {
    const { source, destination, type } = results;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === 'group') {
      const reorderedImages = [...imagesAdd];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedImage] = reorderedImages.splice(sourceIndex, 1);
      reorderedImages.splice(destinationIndex, 0, removedImage);

      return setImagesAdd(reorderedImages);
    }
  };

  const handleSubmitAdd = async () => {
    setModalLoading(true);
    const data = [];
    try {
      const formData = new FormData();
      imagesAdd.forEach((image, index) => {
        const uniqueId = uuidv4();
        formData.append(
          'images',
          image.url,
          uniqueId +
            '_' +
            image.url.name
              .substring(0, image.url.name.lastIndexOf('.'))
              .replace(' ', '_')
              .replace('.', '_'),
        );
        data.push({
          id: uniqueId,
          comment: image.comment,
          order: index + 1,
        });
      });
      formData.append('data', JSON.stringify(data));
      const response = await postImagesBoat(
        formData,
        API_ROUTES.BOATS.POST.IMAGES,
        id,
      );
      if (response.status === 200) {
        setImagesAdd([]);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await getDataID(API_ROUTES.BOATS.GET.IMAGES, id);
      setImages(response.data);
      setImagesUpdate(response.data);
    } catch (error) {
      console.log('carousel handlesubmitadd try get error ', error);
    }
    setModalLoading(false);
    setShowAdd(false);
  };

  const handleDragDropUpdate = (results) => {
    const { source, destination, type } = results;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === 'group') {
      const reorderedImages = [...imagesUpdate];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedImage] = reorderedImages.splice(sourceIndex, 1);
      reorderedImages.splice(destinationIndex, 0, removedImage);

      // Updated the `order` attribute of each image to match its new index + 1
      const updatedImages = reorderedImages.map((image, index) => ({
        ...image,
        newOrder: index + 1,
      }));

      return setImagesUpdate(updatedImages);
    }
  };

  const handleDelete = (img) => {
    setImagesUpdate(imagesUpdate.filter((item) => item.id !== img.id));
    setImages(images.filter((item) => item.id !== img.id));
    setImagesDeleted((prev) => [...prev, img]);
  };

  const handleSubmitUpdate = async () => {
    setModalLoading(true);
    if (imagesDeleted.length > 0) {
      const params = new URLSearchParams();
      imagesDeleted.forEach((item) => params.append('id', item.id));
      const response = await deleteDataID(API_ROUTES.BOATS.DELETE.IMAGES, id, {
        params,
        withCredentials: true,
      });
      if (response.data.quantity > 0) {
        setImagesDeleted([]);
      }
    }
    if (imagesUpdate) {
      try {
        const response = await putDataID(
          API_ROUTES.BOATS.PUT.IMAGES,
          imagesUpdate,
          id,
        );

        if (response.status === 200) {
          const result = [...imagesUpdate];
          for (const image of result) {
            image.order = image.newOrder;
          }
          setImages(result);
          setImagesUpdate(result);
        }
      } catch (error) {
        console.log('carousel update L244 error ', error);
      }
    }
    setModalLoading(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spinner animation="border" role="status" className="carouselSpinner">
          <span className="visually-hidden">Loading images...</span>
        </Spinner>
      ) : (
        <React.Fragment>
          {user && (
            <div className="updateContainer">
              <Button
                variant="primary"
                onClick={() => setShowAdd(true)}
                className="updateContainer__button"
              >
                <IoMdAddCircle />
              </Button>
              <Button
                variant="info"
                disabled={imagesUpdate.length === 0}
                onClick={() => setShowUpdate(true)}
                className="updateContainer__button"
              >
                <HiOutlinePencilSquare />
              </Button>
            </div>
          )}
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="carousel"
          >
            {images.length > 0 ? (
              images
                .sort((a, b) => a.order - b.order)
                .map((image) => {
                  return (
                    <Carousel.Item key={image.id}>
                      <img
                        src={
                          image.link.includes('photos.boats-diffusion')
                            ? image.link
                            : image.order > 0 && image.order < 16
                            ? `${LOCAL_URL}/images/w${image.link}`
                            : `${LOCAL_URL}/images/${image.link}`
                        }
                        alt={image.comment ? image.comment : image.id}
                      />
                      <Carousel.Caption>
                        {image.comment && <span>{image.comment}</span>}
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })
            ) : (
              <Carousel.Item>
                <img
                  src={placeholder}
                  alt="Shipyard"
                  className="carousel__placeholder"
                />
              </Carousel.Item>
            )}
          </Carousel>
          <Modal
            show={showAdd}
            onHide={() => setShowAdd(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add new photos</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal__body">
              <DragDropContext
                onDragEnd={handleDragDropAdd}
                className="App__display"
              >
                <div className="App__display__upload">
                  <input
                    ref={inputRef}
                    onChange={(e) => handleImage(e)}
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    multiple
                    hidden
                  />
                  <Button
                    onClick={handleButton}
                    className="App__display__upload__button"
                  >
                    <FaFileUpload />
                    <span>Click to add images</span>
                  </Button>
                </div>
                <Droppable droppableId="main" type="group">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {imagesAdd?.map((element, index) => {
                        return (
                          <Draggable
                            draggableId={element.id}
                            key={element.id}
                            index={index}
                          >
                            {(provided) => (
                              <Card
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className="cardimage"
                              >
                                <Card.Img
                                  variant="bottom"
                                  src={URL.createObjectURL(element.url)}
                                  alt={`Uploaded ${element.id}`}
                                  className="cardimage__image"
                                />
                                <Card.Body className="cardimage__body">
                                  <div className="cardimage__body__upper">
                                    <Form.Control
                                      type="text"
                                      value={index + 1}
                                      onChange={(e) =>
                                        array_move(
                                          index,
                                          parseInt(e.target.value),
                                          imagesAdd,
                                          setImagesAdd,
                                        )
                                      }
                                      className="cardimage__body__upper__field"
                                    />
                                    <MdDragIndicator className="cardimage__body__upper__drag" />
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        setImagesAdd(
                                          imagesAdd.filter(
                                            (item) => item.id !== element.id,
                                          ),
                                        )
                                      }
                                      className="cardimage__body__upper__button"
                                    >
                                      <MdDelete />
                                    </Button>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    onChange={(e) =>
                                      (element.comment = e.target.value)
                                    }
                                    placeholder="Description"
                                  />
                                </Card.Body>
                              </Card>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleSubmitAdd}
                variant="success"
                disabled={modalLoading}
              >
                {modalLoading && <Spinner className="spinner" />}Validate
              </Button>
              <Button variant="danger" onClick={() => setShowAdd(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showUpdate}
            onHide={() => setShowUpdate(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update photos</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal__body">
              <DragDropContext
                onDragEnd={handleDragDropUpdate}
                className="App__display"
              >
                <Droppable droppableId="main" type="group">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {imagesUpdate?.map((element, index) => {
                        return (
                          <Draggable
                            draggableId={`${element.id}`}
                            key={element.id}
                            index={index}
                          >
                            {(provided) => (
                              <Card
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className="cardimage"
                              >
                                <Card.Img
                                  variant="bottom"
                                  src={
                                    element.order === 0
                                      ? element.link
                                      : element.order > 0 && element.order < 16
                                      ? `${LOCAL_URL}/images/w${element.link}`
                                      : `${LOCAL_URL}/images/${element.link}`
                                  }
                                  alt={`Uploaded ${element.id}`}
                                  className="cardimage__image"
                                />
                                <Card.Body className="cardimage__body">
                                  <div className="cardimage__body__upper">
                                    <Form.Control
                                      type="text"
                                      value={index + 1}
                                      onChange={(e) =>
                                        array_move(
                                          index,
                                          parseInt(e.target.value),
                                          imagesUpdate,
                                          setImagesUpdate,
                                        )
                                      }
                                      className="cardimage__body__upper__field"
                                    />
                                    <MdDragIndicator className="cardimage__body__upper__drag" />
                                    <Button
                                      variant="danger"
                                      onClick={() => handleDelete(element)}
                                      className="cardimage__body__upper__button"
                                    >
                                      <MdDelete />
                                    </Button>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    defaultValue={element.comment}
                                    onChange={(e) =>
                                      (element.comment = e.target.value)
                                    }
                                    placeholder="Description"
                                  />
                                </Card.Body>
                              </Card>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleSubmitUpdate}
                variant="success"
                disabled={modalLoading}
              >
                {modalLoading && <Spinner className="spinner" />}Validate
              </Button>
              <Button variant="danger" onClick={() => setShowUpdate(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
